.m-l-custom-email-input {
  .react-multi-email-custom {
    padding: 6px 8px 6px 8px !important;
    min-height: 37px;
    border: 1px solid $app-secondary-border-color !important;
    @include border-radius-all(4px);
    color: $app-brand-secondary !important;
    font-weight: $font-weight-normal;
    font-size: $text-lg !important;
    @include flexboxv2();
    @include flex-wrapv2(wrap);
    max-height: 57px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: $white;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(191, 191, 191, 0.7);
      @include border-radius-all(4px);
    }
  }

  .react-multi-email-custom.focused {
    border-color: $app-secondary-border-color !important;
  }

  .react-multi-email-custom > input {
    color: $app-brand-secondary !important;
    font-weight: $font-weight-normal;
    font-size: $text-lg !important;
    padding: 0px !important;
    outline: none !important;
    border: 0 none !important;
  }
  &.m-l-validation {
    .react-multi-email-custom {
      border-color: $red !important;
    }
  }
}
.data-tag-holder {
  font-weight: $font-weight-semibold;
  font-size: $text-md;
  line-height: 1.3;
  padding: 2px 10px;
  background: $grid-row-selection;
  @include border-radius-all(34px);
  margin-bottom: 3px;
  min-height: 20px;
  @include flexboxv2();
  .data-tag-close {
    margin-left: 6.5px;
    cursor: pointer;
  }
  + .data-tag-holder {
    margin-left: 5px;
  }
}
.data-tag-validation {
  color: $red;
  font-weight: $font-weight-normal;
  font-size: $text-md;
  line-height: 1.33;
}
