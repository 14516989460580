@import '../variables/variables';

body {
  font-family: $body-font;
  font-size: $text-lg;
  color: $base-font-color;
  background-color: $body-color;
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
}
.dx-widget {
  font-family: $body-font;

  input {
    font-family: $body-font;
  }
}
a {
  color: $app-brand-primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
b,
strong {
  font-weight: $font-weight-bold;
}

::selection {
  color: $text-color;
  background: rgba($app-brand-primary, 0.3);
}

::-moz-selection {
  color: $text-color;
  background: rgba($app-brand-primary, 0.3);
}
.dx-drawer-shrink {
  .dx-drawer-wrapper {
    @media screen and (max-width: 891px) {
      display: block;
    }
  }
}
