.m-l-modal-main__wrap {
  .m-l-modal__header {
    padding: 24px 32px 32px 32px;
    @include flexboxv2();
    @include align-itemsv2(center);
    @include flex-shrinkv2(0);
    position: relative;
    .modal-title {
      font-weight: $font-weight-extrabold;
      font-size: $text-xl-sm;
      line-height: 1.33;
      color: $app-brand-primary;
    }

    .m-l-modal-close-button-block {
      margin-left: auto;
      position: absolute;
      top: 11px;
      right: 10px;
      z-index: 10;
      .app-c-icon-only-btn {
        width: 34px;
        height: 34px;
        min-width: 34px;
        min-height: 34px;
      }
    }
  }
  .dx-popup-normal {
    padding: 0 !important;
    max-width: calc(100% - 20px) !important;
    height: auto;
    max-height: 90% !important;
    @media (max-width: 991px) {
      max-width: calc(100% - 20px) !important;
      margin: 0px 20px;
    }

    .dx-popup-content {
      > div {
        height: 100%;
        display: flex !important;
        @include flex-directionv2(column);
      }
    }
  }
  .m-l-modal__body {
    height: 100%;
    padding: 0px 32px;
    overflow: auto;
    &.additional-data-capturing-body-block {
      .dx-scrollable {
        .dx-scrollable-wrapper {
          .dx-scrollable-container {
            .dx-scrollable-content {
              padding-right: 16px;
            }
          }
        }
      }
    }
    .dx-scrollview {
      .dx-scrollable-wrapper {
        .dx-scrollable-container {
          .dx-scrollbar-vertical {
            right: -20px;
          }
        }
      }
    }
  }
  .m-l-modal__footer {
    padding: 0px 32px 32px 32px;
    @include flexboxv2();
    @include align-itemsv2(center);
    @include justify-contentv2(flex-end);
    @media (max-width: 420px) {
      @include flex-directionv2(column);
      @include justify-contentv2(flex-start);
      @include align-itemsv2(flex-start);
    }
    .app-c-btn {
      @media (max-width: 420px) {
        width: 100%;
      }
      + .app-c-btn {
        margin-left: 8px;
        @media (max-width: 420px) {
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }
    .m-l-footer-left-block {
      width: 100%;
      .m-l-email-atch-icon-hldr {
        color: $dark-blue;
        margin-left: 18px;
        cursor: pointer;
      }
      @media (max-width: 420px) {
        margin-bottom: 8px;
      }
    }
    .m-l-footer-right-block {
      margin-left: auto;
      @include flexboxv2();
      @include align-itemsv2(center);
      @media (max-width: 420px) {
        width: 100%;
        @include flex-directionv2(column);
        @include align-itemsv2(flex-start);
        margin-left: 0px;
      }
    }
  }

  &.m-l-modal-switch__bu {
    &.dx-popup-wrapper {
      .dx-popup-normal {
        max-width: 390px !important;
        height: auto;
        max-height: 100% !important;
        padding: 22px 34px 58px 34px !important;
        .dx-popup-content .m-l-confirm-dialogbox-header .dx-button {
          top: -10px;
          right: -24px;
        }
        .dx-popup-content .m-l-confirm-dialogbox-footer .app-c-btn {
          width: auto;
          min-width: 120px;
        }

        @media (max-width: 360px) {
          max-width: 320px !important;
        }
        @media (max-width: 320px) {
          max-width: 300px !important;
        }
      }
    }
  }
  .m-l-confirmation-selection-field {
    margin-top: 22px;
  }
  .m-l-confirmation-dialogbox-title {
    margin-bottom: 16px;
  }
  // &.m-l-model-popup--rfi-vessal-owner {
  //   .dx-popup-normal {
  //     width: 70vw !important;
  //     height: 90vh !important;
  //     max-width: 80vw !important;
  //     max-height: 90vh !important;
  //   }
  //   .m-l-modal__body {
  //     height: calc(100% - 135px);
  //     overflow: auto;
  //   }
  //   .m-l-modal__footer {
  //     @include flexboxv2();
  //     @include align-itemsv2(center);

  //     .app-c-btn {
  //       width: auto !important;
  //     }
  //     .m-l-footer-left-block {
  //       .m-l-email-atch-icon-hldr {
  //         color: $dark-blue;
  //         margin-left: 18px;
  //       }
  //     }
  //     .m-l-footer-right-block {
  //       margin-left: auto;
  //       @include flexboxv2();
  //       @include align-itemsv2(center);
  //     }
  //   }
  // }
  .m-l-input-label-padding {
    padding-bottom: 10px;
  }
  &.m-l-modal-change__pwd {
    &.dx-popup-wrapper {
      .dx-popup-normal {
        max-width: 400px !important;
        height: auto !important;
        max-height: 100% !important;
        padding: 22px 28px !important;
        .m-l-confirm-dialogbox-header {
          &.m-l-cmn-modal-header {
            .m-l-hdr-mdl-title {
              font-weight: $font-weight-extrabold;
              font-size: $text-xl-sm;
              line-height: 1.33;
              color: $app-brand-primary;
            }
          }
        }
        .dx-popup-content .m-l-confirm-dialogbox-header .dx-button {
          top: -12px;
          right: -20px;
        }
        .m-l-pwd-chng-wrap {
          margin-top: 22px;
          .m-c-form-group .m-l-input-label {
            margin-bottom: 7px;
          }
        }
        .m-l-chp-mdl-footer {
          @include flexboxv2();
          @include justify-contentv2(flex-end);
          .app-c-btn {
            @media (max-width: 420px) {
              width: 100%;
            }
          }
        }
        @media (max-width: 420px) {
          width: calc(100% - 20px) !important;
          margin: 0px 10px;
        }
      }
    }
  }
  &.m-l-modal__success {
    &.dx-popup-wrapper {
      .dx-popup-normal {
        max-width: 400px !important;
        height: auto;
        max-height: 100% !important;
        padding: 24px 32px 40px 32px !important;

        .dx-popup-content .m-l-confirm-dialogbox-header .dx-button {
          top: -12px;
          right: -20px;
        }
        @media (max-width: 420px) {
          width: calc(100% - 20px) !important;
          margin: 0px 10px;
        }
      }
    }
  }
  &.m-c-comment-popup {
    .dx-popup-normal {
      padding: 0 !important;
      max-width: 50vw !important;
      height: auto;
      max-height: 90% !important;
      @media (max-width: 991px) {
        max-width: 50vw !important;
        margin: 0px 20px;
      }
      @media (max-width: 767px) {
        max-width: calc(100% - 20px) !important;
        margin: 0px 20px;
      }
    }
  }
  //Scollbar Style

  // .dx-scrollable-container {
  //   .dx-scrollable-scrollbar {
  //     .dx-scrollable-scroll {
  //       width: 11px !important;
  //       &.dx-state-invisible {
  //         opacity: 1 !important;
  //         .dx-scrollable-scroll-content {
  //           // background-color: rgba(
  //           //   $color: #174A84,
  //           //   $alpha: 0.1
  //           // ) !important;
  //           background-color: #174a84 !important;
  //           @include border-radius-all(20px);
  //         }
  //       }
  //     }
  //     &.dx-scrollbar-vertical {
  //       &.dx-scrollbar-hoverable {
  //         width: 11px !important;
  //         &.dx-state-hover,
  //         &.dx-scrollable-scrollbar-active {
  //           width: 11px !important;
  //           background-color: $grid-row-selection !important;
  //         }
  //         .dx-scrollable-scroll {
  //           .dx-scrollable-scroll-content {
  //             background-color: #174a84 !important;
  //             @include border-radius-all(20px);
  //             // height: 11px !important;
  //           }
  //         }
  //       }
  //     }
  //     &.dx-scrollbar-hoverable &.dx-state-hover {
  //       .dx-scrollable-scroll {
  //         width: 11px !important;
  //       }
  //     }
  //   }
  // }
  // .dx-scrollable-scroll-content {
  //   background-color: #174a84 !important;
  //   width: 11px !important;
  // }

  &.m-l-modal__layout {
    .dx-overlay-content {
      @include flexboxv2();
      @include flex-directionv2(column);
    }
    .dx-popup-content {
      height: 100% !important;
      min-height: 0 !important;

      @include flexboxv2();
      @include flex-directionv2(column);

      > div {
        min-height: 0;
      }

      .m-l-modal__body {
        padding-bottom: 16px;

        @include flexboxv2();
        @include flex-directionv2(column);

        .m-c-grid {
          min-height: 0;
          height: 100%;

          @include flexboxv2();
          @include flex-directionv2(column);

          .m-l-with-filter-row-in-grid {
            height: 100%;
            min-height: 0;

            @include flexboxv2();
            @include flex-directionv2(column);
          }
        }
      }
    }
  }
}
.m-l-modal-mailcontent {
  .m-l-modal__footer {
    @media (max-width: 620px) {
      @include flex-directionv2(column);
      @include justify-contentv2(flex-start);
      @include align-itemsv2(flex-start);
    }
    .app-c-btn {
      @media (max-width: 600px) {
        width: 100%;
      }
      + .app-c-btn {
        margin-left: 8px;
        @media (max-width: 600px) {
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }
    .m-l-footer-left-block {
      @include flexboxv2();
      @include align-itemsv2(flex-start);
      .m-l-email-atch-icon-hldr {
        color: $dark-blue;
        margin-left: 18px;
        cursor: pointer;
      }
      @media (max-width: 600px) {
        margin-bottom: 8px;
      }
    }
    .m-l-footer-right-block {
      margin-left: auto;
      @include flexboxv2();
      @include align-itemsv2(center);
      @media (max-width: 600px) {
        width: 100%;
        @include flex-directionv2(column);
        @include align-itemsv2(flex-start);
        margin-left: 0px;
      }
    }
  }
  .dx-popup-normal {
    width: calc(100% - 140px) !important;
  }
}
.m-l-modal-excel-upload-grid {
  .dx-popup-normal {
    width: calc(100% - 240px) !important;
  }
}

.m-l-modal__fileupload {
  .m-l-modal__header {
    padding-top: 0px;
  }
  .m-l-file-upload-popup {
    height: 100%;
    display: flex !important;
    @include flex-directionv2(column);
  }
  .m-l-file-upload-modal-body {
    .m-l-fl-title {
      font-weight: $font-weight-extrabold;
      font-size: $text-xl-sm;
      line-height: 1.33;
      color: $app-brand-primary;
      padding-bottom: 18px;
    }
    .m-l-fl-upl-btn {
      margin: 6px 0px;
    }
    .m-l-fl-icon {
      margin-top: 16px;
      margin-bottom: 16px;
      @include flexboxv2();
      @include justify-contentv2(center);
    }
    .m-l-slct-fl-note {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.71;
      padding-bottom: 20px;
      color: $app-brand-secondary;
    }
  }
  .m-l-modal__footer {
    @include justify-contentv2(center);
    padding-top: 15px;
  }
}
.m-l-confirmation-dialogbox-wrap {
  .dx-button {
    &.dx-state-hover,
    &.dx-state-focused {
      background-color: $grid-row-selection;
    }
  }
}
.m-l-status-message {
  font-weight: $font-weight-normal;
  font-size: $text-lg;
  line-height: 1.71;
  text-align: center;
  color: $app-secondary-border-color;
  margin-top: 16px;
}

.m-l-modal-appeal-request {
  .m-l-modal__header {
    padding-bottom: 15px;
  }

  .m-l-modal__footer {
    padding-top: 18px;
  }
}

.m-l-add-new-incident-modal {
  .m-l-modal__header {
    padding-bottom: 15px;
  }

  .m-l-adnw-icdnt-form-panel {
    padding: 16px 46px 0px 16px;
    @include border-radius-all(8px);
    border: 1px solid $semi-gray;
    margin-bottom: 1px;
  }

  .m-l-comment-row {
    @include align-itemsv2(flex-end);
  }
  .m-l-modal__footer {
    padding-top: 18px;
  }
  .m-l-desc-section {
    margin-top: 0px;
  }
  .pt-12 {
    padding-top: 12px;
    @media (max-width: 576px) {
      padding-top: 0px;
    }
  }
}
.m-l-psc-modal {
  .m-l-modal__header {
    padding-bottom: 15px;
  }
  .m-l-psc-form-panel {
    padding: 16px 58px 6px 16px;
    @include border-radius-all(8px);
    border: 1px solid $semi-gray;
    margin-bottom: 1px;
  }
  .m-l-modal__footer {
    padding-top: 18px;
  }
  .m-l-comment-row {
    @include align-itemsv2(flex-end);
  }
  .m-l-modal-form-footer {
    @include flexboxv2();
    .m-l-group-btn-wrap {
      margin-left: auto;
      width: 100%;
    }
  }
  .m-l-desc-section {
    margin-top: 18px;
  }
  .pt-12 {
    padding-top: 12px;
    @media (max-width: 768px) {
      padding-top: 0px;
    }
  }
  .m-l-data-entry-wrapper {
    margin-top: 18px;
    @media (max-width: 768px) {
      margin-top: 18px;
    }
  }
  .tp-space-det {
    padding-top: 11px;
    @media (max-width: 768px) {
      padding-top: 0px;
    }
  }
  .tp-space-status {
    padding-top: 21px;
    @media (max-width: 768px) {
      padding-top: 0px;
    }
  }
  .tp-space-source {
    padding-top: 21px;
    @media (max-width: 768px) {
      padding-top: 0px;
    }
  }
  .tp-space-cls {
    padding-top: 10px;
    @media (max-width: 768px) {
      padding-top: 0px;
    }
  }
  .m-l-button-vertical-center-align.m-l-fm-icon-btn {
    @media (max-width: 576px) {
      width: 100%;
      min-height: auto;
      height: auto;
    }
    .app-c-btn--remove {
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
  .min-btn-width-sm-pls {
    @media (max-width: 576px) {
      width: 100% !important;
    }
  }
  .m-l-horizontal-line {
    margin-top: 0px;
    margin-bottom: 30px;
    background-color: $semi-gray;
    opacity: 1;
    display: none;
    @media (max-width: 1200px) {
      display: block;
    }
  }
}
.ml-16 {
  margin-left: 16px;
}
.mail-list-form {
  padding-right: 0px;
  @include flexboxv2();
  @include flex-directionv2(column);
}

.m-l-form-modal {
  .m-l-modal__header {
    padding-bottom: 32px;
  }
}
