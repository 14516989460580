.m-l-toggle-switch {
  .dx-switch {
    width: 40px;
    height: 21px;
    &.dx-switch-on-value {
      .dx-switch-handle::before {
        background-color: $white;
      }
    }
    .dx-switch-container {
      padding: 0px;
      height: 21px;
      border: 1px solid $app-secondary-border-color;
      background: $app-secondary-border-color;
      @include border-radius-all(36.5px);
      .dx-switch-on,
      .dx-switch-off {
        color: transparent;
      }
      .dx-switch-handle {
        width: 19px;
        height: 19px;
        background-color: $white;
        @include border-radius-all(50%);
        transform: translateX(2%) !important;
        transition: all 100ms ease 0ms !important;
        margin-top: 0px;
        // position: relative;
        // right: 1px;
        &:before {
          width: 19px;
          height: 19px;
          @include border-radius-all(50%);
          background-color: $white;
        }
      }
      // .dx-switch-inner {
      //   position: relative;
      //   left: 2px;
      // }
    }
    &.dx-switch-on-value {
      .dx-switch-container {
        padding: 0px;
        height: 21px;
        border: 1px solid $green-sw;
        background: $green-sw;
        .dx-switch-inner {
          .dx-switch-handle {
            transform: translateX(-102%) !important;
          }
        }
      }
    }
  }

  &.m-l-tg-switch-align {
    padding-top: 9px;
  }
}
