.dx-popup-wrapper {
  &.dx-lookup-popup-wrapper {
    .dx-scrollview-content {
      max-height: 400px;
    }
    .dx-popup-normal {
      width: 302px !important;
      .dx-popup-title {
        border-bottom: 3px solid rgba($semi-gray, 0.5);
        padding-left: 24px;
        min-height: 54px;
        &.dx-toolbar {
          .dx-toolbar-label {
            font-weight: $font-weight-bold;
            font-size: $text-lg;
            line-height: 1.71;
            color: $app-brand-secondary;
          }
        }
      }
      .dx-popup-content {
        @include flex-directionv2(column);
      }
      .dx-lookup-search-wrapper {
        padding: 8px 24px 0px;
        .dx-lookup-search {
          border: none;
        }
        .dx-searchbox {
          .dx-icon-search {
            text-align: left;
            width: auto;
            padding-left: 0px;
            margin-top: -18px;
            &:before {
              content: url('../../../assets/images/icons/Union.svg');
            }
          }
          .dx-texteditor-input {
            padding-left: 24px;
          }
          .dx-placeholder {
            font-size: $text-lg;
            font-weight: $font-weight-normal;
            color: $placeholder-color;
          }
          .dx-placeholder::before,
          .dx-texteditor-input {
            padding-left: 24px;
          }
        }
      }
      .dx-list {
        height: calc(100% - 122px);
        .dx-list-item {
          &.dx-state-hover,
          &.dx-state-active {
            background-color: $grid-row-selection;
          }
          .dx-list-item-content {
            padding: 8px 24px;
            .m-l-look-up-pic-nm-wrap {
              @include flexboxv2();
              @include align-itemsv2(center);
            }
            .m-l-lookup-prof-pic {
              width: 24px;
              height: 24px;
              @include border-radius-all(50%);
            }
            .m-l-lookup-drp-text {
              padding-left: 16px;
              font-weight: 500;
              font-size: 12px;
              line-height: 1.33;
              color: #585c64;
            }
          }
        }
      }
    }
    .dx-popup-bottom.dx-toolbar {
      display: none;
    }
  }
  .dx-toolbar-label > div {
    margin-top: -5px;
  }
}
.m-l-look-up-pic-nm-wrap-heading-header {
  .m-l-look-up-pic-nm-wrap {
    @include flexboxv2();
    @include align-itemsv2(center);
  }

  .m-l-lookup-drp-text {
    padding-left: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.33;
    color: #585c64;
  }
}

.assign-to-text {
  font-weight: 500;
  font-size: 14px;
  color: #585c64;
  margin-bottom: 8px;
}
.m-l-look-up-pic-nm-wrap-heading-header {
  .m-l-look-up-pic-nm-wrap {
    @include flexboxv2();
    @include align-itemsv2(center);
  }

  .m-l-lookup-drp-text {
    padding-left: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.33;
    color: #585c64;
  }
}

.assign-to-text {
  font-weight: 500;
  font-size: 14px;
  color: #585c64;
  margin-bottom: 8px;
}
