@import './../../../assets/scss/core/mixins/flexbox-v2';
@import './../../../assets/scss/main';

.ui-page {
  .m-c-header-section,
  .m-c-sidebar {
    display: none;
  }
}
.app-l-ui-layout {
  background-color: $white;
}
.ui-header {
  @include flexboxv2();
  @include justify-contentv2(space-between);
  background-color: $app-brand-primary;
  padding: 15px;
  color: $white;
}
.app-l-cmp-wrapper {
  padding-top: 60px;
  .app-l-cmp-row {
    @include flexboxv2();
    .app-l-cmp-items {
      + .app-l-cmp-items {
        margin-left: 50px;
      }
      &.full-width {
        width: 100%;
      }
    }
    + .app-l-cmp-row {
      margin-top: 30px;
    }
    .app-l-cmp-icons {
      + .app-l-cmp-icons {
        margin-left: 15px;
      }
    }
  }
  &.form-elements {
    .app-l-cmp-items {
      width: 250px;
    }
  }
}
.app-l-color-pallete {
  width: 200px;
  height: 150px;
  color: $white;
  @extend %flexbox;
  @include align-itemsv2(center);
  @include justify-contentv2(center);
  border-radius: 16px;
  font-size: $text-xl-sm;
  &.primary-color {
    background-color: $app-brand-primary;
  }
  &.secondary-color {
    background-color: $app-brand-secondary;
  }
  &.primaryblue-color {
    background-color: $app-primary-blue;
  }
}
.app-l-uicontainer {
  margin-bottom: 60px;
}
.app-l-cmp-items-nw {
  width: 100%;
}
