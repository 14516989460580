.m-l-tree-view {
  position: relative;
  .dx-treeview {
    .dx-treeview-node-container {
      &.dx-treeview-node-container-opened {
        .dx-treeview-node-is-leaf {
          display: inline-flex;
        }
      }
      .dx-treeview-node {
        .dx-checkbox {
          .dx-checkbox-container {
            width: 16px;
            height: 16px;
            border: 1.5px solid $dark-blue;
            @include border-radius-all(3px);
            .dx-checkbox-icon {
              width: 16px;
              height: 16px;
              &:before {
                margin-left: -1.4px;
                margin-top: 0px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 11px;
                font-weight: $font-weight-extrabold;
                color: $dark-blue;
              }
            }
          }
          &.dx-checkbox-indeterminate {
            .dx-checkbox-container {
              .dx-checkbox-icon {
                &:before {
                  font-size: 8px;
                  margin-left: -1.5px;
                  color: $dark-blue;
                }
              }
            }
          }
        }
        > .dx-treeview-node-container {
          > .dx-treeview-node {
            > .dx-treeview-node-container-opened {
              width: 100%;

              @include flexboxv2();
              @include flex-wrapv2(wrap);

              > .dx-treeview-node {
                width: 20%;

                @media screen and (max-width: 1400px) {
                  width: 25%;
                }
                @media screen and (max-width: 1200px) {
                  width: 33.333%;
                }
                @media screen and (max-width: 767px) {
                  width: 50%;
                }
                @media screen and (max-width: 480px) {
                  width: 100%;
                }
                > .dx-treeview-item {
                  margin-right: 0;
                  white-space: normal;

                  @include flex-basisv2(100%!important);
                }
              }
            }
          }
        }
      }
      li {
        &.dx-treeview-node {
          .dx-treeview-node-container {
            .dx-treeview-item {
              flex: 0 0 300px;
              @media screen and (max-width: 1200px) {
                @include flex-basisv2(230px);
              }
              @media screen and (max-width: 991px) {
                flex: 0 0 150px;
              }
              @media screen and (max-width: 768px) {
                flex: 0 0 auto;
              }
              @media screen and (max-width: 480px) {
                flex: 1 1 100%;
              }
            }
            li.dx-treeview-node {
              @include flexboxv2();
              @media screen and (max-width: 768px) {
                @include flex-directionv2(column);
              }
              @media screen and (max-width: 480px) {
                display: block;
              }
              .dx-treeview-node-container-opened {
                @include flexboxv2();
                flex-wrap: wrap;
              }
            }
          }
        }
      }
      li {
        .dx-treeview-item {
          padding-top: 8px;
          padding-bottom: 8px;
          background: $grd-bg;
          padding-left: 76px;
        }
        .dx-checkbox {
          top: 11px;
          left: 58px;
        }
        .dx-treeview-toggle-item-visibility {
          top: 7px;
        }
        .dx-item-content {
          font-weight: $font-weight-bold;
          font-size: $text-lg;
          line-height: 1.71;
          color: $text-color;
        }
        ul {
          padding-left: 12px;
          li {
            padding-top: 8px;
            padding-bottom: 8px;
            &:hover {
              background-color: rgba($grid-row-selection, 0.3);
            }
            .dx-item.dx-treeview-item {
              // padding-top: 18px;
              // padding-bottom: 18px;
              padding-top: 10px;
              padding-bottom: 10px;
              background: transparent;
              padding-left: 48px;

              .dx-item-content {
                font-weight: $font-weight-normal;
                font-size: $text-md;
                line-height: 1.33;
                color: $dark-blue;
                margin-left: 2px;
              }
            }
            .dx-treeview-toggle-item-visibility {
              // top: 22px;
              top: 13px;
            }
            .dx-checkbox {
              // top: 27px;
              // left: 25px;
              top: 18px;
              left: 31px;
            }
            ul {
              li {
                .dx-item.dx-treeview-item {
                  flex-basis: auto !important;
                  padding-left: 42px;
                  margin-right: 14px;
                  white-space: nowrap;
                  .dx-item-content {
                    font-weight: $font-weight-normal;
                    font-size: $text-md;
                    line-height: 1.33;
                    color: $dark-blue;
                    margin-left: 0px;
                  }
                }
                &:last-child {
                  .dx-item.dx-treeview-item {
                    margin-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
      content: '\f016';
      font-size: $text-lg;
      color: $dark-blue;
      font-weight: $font-weight-bold;
      line-height: 1.3;
      position: absolute;
      right: 12px;
    }
    .dx-treeview-toggle-item-visibility::before {
      content: '\f010';
      font-size: $text-lg;
      color: $dark-blue;
      font-weight: $font-weight-bold;
      line-height: 1.3;
      position: absolute;
      top: 15.5px;
      right: 12px;

      padding-left: 12px;
    }
    .dx-item-content {
      color: $dark-blue;
      font-size: $text-md;
    }
  }
  .m-l-tr-hd-wrap {
    @media screen and (max-width: 480px) {
      margin-bottom: 30px;
    }
    .m-l-ftr-ttl {
      position: absolute;
      left: 413px;
      top: 31px;
      z-index: 1;
      font-weight: $font-weight-bold;
      font-size: $text-lg;
      line-height: 1.71;
      color: $text-color;
      @media screen and (max-width: 991px) {
        left: 262px;
      }
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
    .m-l-expand {
      position: absolute;
      right: 50px;
      top: 31px;
      z-index: 1;
      font-weight: $font-weight-bold;
      font-size: $text-lg;
      line-height: 1.71;
      color: $text-color;
      @media screen and (max-width: 991px) {
        left: 262px;
      }
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    .m-l-role-switch {
      position: absolute;
      left: 613px;
      top: 31px;
      z-index: 1;
      font-weight: $font-weight-bold;
      font-size: $text-lg;
      line-height: 1.71;
      color: $text-color;
      @media screen and (max-width: 1200px) {
        left: 80px;
        top: 8px;
      }
      @media screen and (max-width: 480px) {
        left: 80px;
        top: 5px;
      }
      .m-l-switch-label {
        padding-right: 10px;
      }
    }
    .m-l-role-legend {
      position: absolute;
      left: 813px;
      top: 31px;
      z-index: 1;
      font-weight: $font-weight-bold;
      font-size: $text-lg;
      line-height: 1.71;
      color: $text-color;
      @media screen and (max-width: 1200px) {
        left: 220px;
        top: 8px;
      }
      @media screen and (max-width: 480px) {
        left: 80px;
        top: 31px;
      }
    }
  }
  .dx-checkbox.dx-state-hover .dx-checkbox-icon {
    border: none;
  }
  .dx-checkbox-icon {
    border: none !important;
  }
}
