.inner-wrapper {
  &.m-l-mail-inner {
    height: 100vh;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 56px;
    @media screen and (max-width: 891px) {
      padding-top: 169px;
    }
  }
}
.m-l-mail-wrapper {
  @include flexboxv2();
  height: 100%;
  max-height: calc(100vh - 118px);

  @media screen and (max-width: 891px) {
    max-height: calc(100vh - 214px);
  }

  //Newly added class
  .resize-pane {
    height: 100% !important;
    // max-height: 100vh !important;
  }
  .m-l-mail-left-holder {
    //Need to correct
    // width: 426px;
    // max-width: 426px;
    height: 100%;
    flex: 0 0 auto;
    border-right: 2px solid $body-color;
    @media screen and (max-width: 891px) {
      width: 100%;
      max-width: 100%;
    }
    .m-l-mail-left-data-wrapper {
      height: 100%;

      @include flexboxv2();
      @include flex-directionv2(column);

      .m-l-mail-left-header {
        @include flexboxv2();
        @include justify-contentv2(space-between);
        @include align-itemsv2(center);
        @include flex-shrinkv2(0);

        padding: 8px;
        border-bottom: 1px solid $semi-gray;
      }
    }
    .m-l-msg-items-wrapper {
      // height: calc(100% - 52px);
      height: calc(100% - 30px);
      overflow: auto;
      .m-l-msg-item {
        @include flexboxv2();
        padding: 12px 16px;
        box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        &:hover,
        &.selected {
          background-color: $grid-row-selection;
        }

        .m-l-email-profile-pic-wrap {
          .m-l-email-profile-pic {
            width: 39px;
            height: 39px;
            @include border-radius-all(50%);
          }
        }
        .m-l-emlr-data-wrap {
          margin-left: 16px;
          flex: 1 1 auto;
          overflow: hidden;
          .m-l-emlr-name {
            font-weight: $font-weight-bold;
            font-size: $text-xl;
            line-height: 1.5;
            color: $app-brand-secondary;
            margin-bottom: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .m-l-emlr-desc {
            font-weight: $font-weight-normal;
            font-size: $text-lg;
            line-height: 1.71;
            color: $app-brand-secondary;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .m-l-eml-cm-tm {
          flex: 0 0 auto;
          padding-left: 16px;
          font-weight: $font-weight-normal;
          font-size: $text-lg;
          color: $app-brand-secondary;
          @include flexboxv2();
          @include align-itemsv2(flex-end);
          @include justify-contentv2(center);
          @include flex-directionv2(column);
          .m-l-eml-time {
            line-height: 1.71;
            + .m-l-eml-attch {
              margin-top: 4px;
            }
          }
          .m-l-eml-attch {
            @include flexboxv2();
            .m-l-eml-atth-icon {
              padding-right: 6px;
              cursor: pointer;
            }
            .m-l-eml-atth-fl {
              line-height: 1.33;
            }
          }
        }
      }
    }
    .m-l-msg-items-wrapper--inside-tab {
      height: calc(100vh - 30px);
    }
  }
  &.m-l-mail-wrapper--bg-white {
    @include border-radius-all(4px);
    background-color: $white;
    .resize-pane {
      height: 100% !important;
      max-height: 100vh !important;

      @media screen and (max-width: 891px) {
        width: 100% !important;
      }
    }
  }
}
.m-l-mail-right-holder {
  flex: 1 1 auto;
  height: 100%;

  @media screen and (max-width: 891px) {
    width: 100%;

    @include flex-shrinkv2(0);
  }

  .m-l-mail-main-data-part {
    height: calc(100vh - 118px);

    .m-l-mail-main-data-header {
      @include flexboxv2();
      // padding: 24px 24px 26px 24px;
      padding: 10px 24px 16px 24px;
      border-bottom: 2px solid $semi-gray;
      @media screen and (max-width: 576px) {
        @include flex-wrapv2(wrap);
      }
      .m-l-mail-main-data-header-left {
        flex: 1;
      }
      .m-l-mail-main-data-header-right {
        flex: 0 1 auto;
        @include flexboxv2();
        @include flex-directionv2(column);
        @include align-itemsv2(flex-end);
        @include align-itemsv2(center);
        @include justify-contentv2(flex-start);
        max-width: 40%;
        margin-bottom: 8px;
        @media screen and (max-width: 576px) {
          @include flex-wrapv2(wrap);
          margin-top: 12px;
          margin-left: 50px;
          @include align-itemsv2(flex-start);
          max-width: 100%;
        }
        .m-l-email-send-date {
          font-weight: $font-weight-semibold;
          font-size: $text-md;
          line-height: 1.33;
          color: $app-secondary-border-color;
          margin-bottom: 8px;

          @media screen and (max-width: 576px) {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
.m-l-email-atch-file-set-wrap {
  @include flexboxv2();
  @include align-itemsv2(flex-start);
  @include flex-wrapv2(wrap);

  .m-l-email-atch-icon-fl-wrap {
    padding: 6px 9px;
    margin-top: auto;
    margin-right: 8px;
    border: 1px solid $semi-gray;
    cursor: pointer;
    @include flexboxv2();
    @include align-itemsv2(center);
    @include border-radius-all(4px);
    .m-l-email-atch-icon-hldr {
      color: $dark-blue;
      margin-right: 10px;
      cursor: pointer;
    }
    .m-l-email-atch-file-type-hldr {
      margin-right: 8px;
    }
    .m-l-email-attach-file-wrap {
      // padding: 2px 10px;
      // background: $grid-row-selection;
      // @include border-radius-all(34px);
      @include flexboxv2();
      @include align-itemsv2(flex-start);
      @include flex-directionv2(column);
      .m-l-email-attach-file-name {
        font-weight: $font-weight-semibold;
        font-size: $text-md;
        line-height: 1.3;
        color: $app-brand-primary;
        margin-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
      }
      .m-l-email-attach-file-size {
        font-weight: $font-weight-normal;
        font-size: $text-sm-plus;
        line-height: 1.3;
        color: $app-brand-secondary;
        // padding-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
      }
      .m-l-files-close-icon {
        margin-left: auto;
        @include flexboxv2();
        @include align-itemsv2(center);
        cursor: pointer;
      }
      + .m-l-email-attach-file-wrap {
        // margin-left: 12px;
        margin-top: 10px;
      }
    }
    + .m-l-email-atch-icon-fl-wrap {
      // margin-left: 12px;
      margin-top: 10px;
    }
  }
}
.m-l-mail-data-prof-sm-data {
  @include flexboxv2();
  .m-l-mail-main-prof-pic-cntr {
    .m-l-email-right-profile-pic {
      width: 32px;
      height: 32px;
      @include border-radius-all(50%);
    }
  }
  .m-l-mail-imp-data-wrap {
    margin-left: 17px;
    .m-l-mail-main-title {
      font-weight: $font-weight-bold;
      font-size: $text-xl-sm;
      line-height: 1.33;
      color: $app-brand-secondary;
      margin-bottom: 4px;
    }
    .m-l-mail-sub-title {
      font-weight: $font-weight-semibold;
      font-size: $text-lg;
      line-height: 1.71;
      color: $app-brand-secondary;
      margin-bottom: 0px;
    }
    .m-l-mail-to-part {
      font-weight: $font-weight-normal;
      font-size: $text-lg;
      line-height: 1.71;
      color: $app-brand-secondary;
      @include flexboxv2();
      @include align-itemsv2(center);

      .m-l-mail-to-id {
        font-weight: $font-weight-semibold;
        font-size: $text-md;
        line-height: 1.33;
        color: $app-secondary-border-color;
        margin-left: 5px;
        display: block;
      }
    }
  }
}

.m-l-mail-main-data-body {
  // padding: 32px 65px 32px 24px;
  padding: 24px 65px 32px 24px;

  .m-l-email-content-wrapper {
    max-width: 780px;
    height: 100%;
    .m-l-email-content-para {
      font-weight: $font-weight-normal;
      font-size: $text-lg;
      line-height: 1.71;
      color: $app-brand-secondary;
      + .m-l-email-content-para {
        padding-top: 20px;
      }
    }
  }
}
.m-l-mail__hide-mob {
  @media screen and (max-width: 891px) {
    display: none;
  }
}

.m-l-back-btn-mob {
  margin: 12px 0px 0px 12px;
  display: none;
  @media screen and (max-width: 891px) {
    display: block;
  }
}

.m-l-search-area-block {
  position: relative;

  .m-c-input-control {
    width: 100%;
    .dx-texteditor-input-container .dx-texteditor-input {
      color: $app-brand-secondary;
      padding: 6px 38px 6px 8px;
    }
  }
  .m-c-search-icon {
    position: absolute;
    top: 7px;
    right: 10px;
  }
}
.m-l-search-typehead-icon {
  text-align: right;
  cursor: pointer;
}
