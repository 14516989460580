@import './../../../assets/scss/variables/variables';
@import './../../../assets/scss/theme/common-color-system';
@import './../../../assets/scss/theme/themes/theme1';
@import './../../../assets/scss/core/mixins/flexbox-v2';
@import './../../../assets/scss/core/mixins/border-radius';
.inner-wrapper {
  &.m-l-usrblck {
    height: 100vh;
    // padding-top: 64px;
    padding-bottom: 0px;
    padding-right: 0px;
    @media screen and (max-width: 991px) {
      height: auto;
    }
  }
}
.m-l-page-tm-dt-desc {
  font-weight: $font-weight-normal;
  font-size: $text-md;
  line-height: 1.6;
  color: $app-secondary-border-color;
}
.m-l-user-dashboard {
  @include flexboxv2();
  height: 100%;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
  .m-l-user-dashboard-left-pane {
    width: 100%;
    overflow: auto;
    padding-top: 8px;
    padding-bottom: 24px;
    padding-right: 15px;

    // &::-webkit-scrollbar-track {
    //   background-color: $body-color;
    // }
    // &::-webkit-scrollbar {
    //   width: 11px;
    //   background-color: $body-color;
    // }
    // &::-webkit-scrollbar-thumb {
    //   @include border-radius-all(10px);
    //   background-color: $thumbclr;
    //   width: 9px;
    // }
    .m-l-user-dashboard-header-part {
      margin-bottom: 18px;
      @include flexboxv2();
      @include align-itemsv2(center);
      .right-block {
        margin-left: auto;
        margin-right: 16px;
      }
    }
  }
  .m-l-user-dashboard-right-pane {
    overflow: auto;
    flex: 1 0 319px;
    @media screen and (max-width: 991px) {
      flex: 0;
      overflow: initial;
      @include flexboxv2();
    }
    @media screen and (max-width: 768px) {
      flex: 0;
      overflow: initial;
      flex-direction: column;
    }
    // &::-webkit-scrollbar-track {
    //   background-color: $body-color;
    // }
    // &::-webkit-scrollbar {
    //   width: 11px;
    //   background-color: $body-color;
    // }
    // &::-webkit-scrollbar-thumb {
    //   @include border-radius-all(10px);
    //   background-color: $thumbclr;
    //   width: 9px;
    // }
  }
}

.m-l-usdata-box-wrapper {
  @include flexboxv2();
  @include flex-wrapv2(wrap);
  margin-left: -8px;
  margin-right: -8px;
  // margin-right: 8px;
  padding: 0;
}
.ml-dashboard-export {
  margin-left: auto;
}

.m-l-usdata-box {
  flex: 0 0 100%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
  .m-l-inner-page-body {
    margin-bottom: 0;
    height: 100%;

    @include flexboxv2();
    @include flex-directionv2(column);

    .m-c-block-grid-list {
      @include flexboxv2();
      @include flex-directionv2(column);

      .m-l-no-filter-row-in-grid {
        height: 100%;
      }
    }
    &.m-l-fxdhd-box {
      min-height: 346px;
      // height: auto;
    }
  }
}
.m-l-us-databox-header {
  @include flexboxv2();
  @include justify-contentv2(space-between);
  padding: 16px;
  &.ml-us-vet-tab {
    padding-bottom: 0px;
  }
  .m-l-us-dbox-ttle {
    font-weight: $font-weight-bold;
    font-size: $text-lg;
    line-height: 1.71;
    color: $app-brand-primary;
  }
  .m-l-us-close-btn {
    color: $dark-blue;
    &.dx-button {
      border: none;
      .dx-button-content {
        padding: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.m-l-us-databox-body {
  padding: 0px 16px 16px 16px;
  .m-l-us-graph-img {
    width: 100%;
  }
}

.grabbable {
  width: 50%;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 16px;
  text-decoration: none;
  float: left;

  @include flex-basisv2(50%);

  @media screen and (max-width: 767px) {
    width: 100%;

    @include flex-basisv2(100%);
  }
  > div {
    height: 100%;

    @include flexboxv2();
    @include flex-directionv2(column);
  }
}
.m-c-grid {
  &.m-c-block-grid {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          height: 200px;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          border-bottom: 1px solid transparent;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          @media screen and (max-width: 991px) {
            max-height: 100%;
          }
          .dx-datagrid-content {
            .dx-datagrid-table {
              .dx-row {
                td {
                  padding: 9.5px 16px;
                }
              }
            }
          }
        }
        .dx-datagrid-headers {
          border-top-color: $semi-gray;
          border-left-color: transparent;
          border-right-color: transparent;
          .dx-datagrid-content {
            .dx-datagrid-table {
              tbody {
                .dx-row.dx-column-lines {
                  td {
                    background: $grd-bg;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.m-c-block-grid-list {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          height: 230px;

          @media screen and (max-width: 991px) {
            max-height: 100%;
          }
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          border-bottom: 1px solid transparent;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          .dx-datagrid-content {
            .dx-datagrid-table {
              .dx-row {
                td {
                  padding: 9.5px 16px;
                }
              }
            }
          }
        }
        .dx-datagrid-headers {
          .dx-datagrid-content {
            .dx-datagrid-table {
              tbody {
                .dx-row.dx-column-lines {
                  td {
                    background: $grd-bg;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.m-l-user-dashboard-right-pane {
  .m-l-usr-alert-block-wrapper {
    border-bottom: 1px solid $semi-gray;
    padding: 26px 27px 10px 20px;
    @media screen and (max-width: 991px) {
      flex-basis: 50%;
    }
    .m-l-usr-alr-block-header {
      @include flexboxv2();
      @include align-itemsv2(center);
      margin-bottom: 10px;
      .m-l-notification-block {
        position: relative;
        width: 19px;
        height: 18px;
        @include flexboxv2();
        @include align-itemsv2(center);
      }
      .m-l-notification-mark {
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: $red;
        position: absolute;
        top: 1px;
        right: 3px;
      }
      .m-l-ntfcn-title {
        font-weight: $font-weight-bold;
        font-size: $text-lg;
        line-height: 1.71;
        color: $app-brand-primary;
        padding-left: 11px;
      }
    }
    .m-l-usr-alr-block-body {
      max-height: calc(50vh - 107px);
      overflow: auto;

      // &::-webkit-scrollbar-track {
      //   background-color: $body-color;
      // }
      // &::-webkit-scrollbar {
      //   width: 11px;
      //   background-color: $body-color;
      // }
      // &::-webkit-scrollbar-thumb {
      //   @include border-radius-all(10px);
      //   background-color: $thumbclr;
      //   width: 9px;
      // }

      .m-l-usr-alr-items-wrapper {
        padding-left: 0px;
        .m-l-usr-alr-item {
          list-style: none;
          cursor: pointer;
          + .m-l-usr-alr-item {
            margin-top: 18px;
          }
        }
        .m-l-rqr-pr-prt {
          @include flexboxv2();
        }
        .m-l-usr-avatar-part {
          padding-right: 19px;
          .m-l-usr-profilepic {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
        .m-l-usr-rqst-part {
          font-weight: $font-weight-semibold;
          font-size: $text-md;
          line-height: 1.33;
          color: $text-color;
          margin-bottom: 4px;
          .mi-usr-rqfr {
            font-weight: $font-weight-normal;
            padding-left: 6px;
          }
          .mi-usr-rqscnd {
            font-weight: $font-weight-bold;
            color: $app-brand-primary;
            &.m-l-rqst-status--pending {
              color: $secondary-brown;
            }
          }
        }

        .m-l-usralr-dt-part {
          font-weight: $font-weight-semibold;
          font-size: $text-md;
          line-height: 1.3;
          color: $app-secondary-border-color;
        }
      }
    }
  }
}

.m-l-eml-dt-wrapper {
  padding-top: 28px;
  .m-l-mail-list-contr {
    margin-left: 8px;
  }
  .m-l-eml-header {
    @include flexboxv2();
    @include align-itemsv2(center);
    padding-left: 16px;
    padding-bottom: 10px;
    .m-l-eml-icon {
      color: $dark-blue;
      margin-right: 10px;
    }
    .m-l-eml-ttl {
      font-weight: $font-weight-bold;
      font-size: $text-lg;
      line-height: 1.71;
      color: $app-brand-primary;
    }
  }
  .m-l-eml-body {
    max-height: calc(50vh - 107px);
    overflow: auto;

    // &::-webkit-scrollbar-track {
    //   background-color: $body-color;
    // }
    // &::-webkit-scrollbar {
    //   width: 11px;
    //   background-color: $body-color;
    // }
    // &::-webkit-scrollbar-thumb {
    //   @include border-radius-all(10px);
    //   background-color: $thumbclr;
    //   width: 9px;
    // }
  }
}
.m-l-us-databox-body {
  .m-l-das-grp-title {
    font-weight: $font-weight-bold;
    font-size: $text-md;
    line-height: 1.33;
    margin-bottom: 13px;

    &.m-l-grp-red-ttl {
      color: $red;
    }
    &.m-l-grp-blue-ttl {
      color: $app-primary-blue;
    }
  }
  .m-l-das-graph-block {
    @include flexboxv2();
    width: 100%;
    > div {
      width: 100%;
    }
    .m-l-das-graph-left-part {
      flex-basis: 50%;
      padding-right: 24px;
    }
    .m-l-das-graph-right-part {
      flex-basis: 50%;
    }
  }
  .m-l-grp-table-data {
    margin-bottom: 30px;
    &.m-l-pdng-btm {
      margin-bottom: 0px;
    }
    .m-l-grp-tbl-row {
      @include flexboxv2();
      .m-l-grp-tbl-col {
        flex: 1;
        font-weight: $font-weight-normal;
        font-size: $text-md;
        line-height: 1.33;
        color: $app-brand-secondary;
        &.right-align {
          text-align: right;
        }
      }
      + .m-l-grp-tbl-row {
        margin-top: 12px;
      }
    }
  }
}
.m-l-vsl-graph-block {
  .m-l-drp-slct-wrap {
    @include flexboxv2();
    @include justify-contentv2(flex-end);
    margin-bottom: 21px;
    .m-l-vsl-dropdown-btn {
      + .m-l-vsl-dropdown-btn {
        margin-left: 18px;
      }
    }
  }
}
