.m-c-accordion {
  width: 100%;
  .dx-accordion {
    @include border-radius-all(8px);
    background-color: transparent;
    .dx-accordion-wrapper {
      .dx-accordion-item {
        border: none;

        &.dx-accordion-item-opened {
          .dx-accordion-item-title {
            &:before {
              transform: rotate(180deg);
            }
          }
        }
        + .dx-accordion-item {
          margin-top: 4px;
        }
      }
      .dx-accordion-item-title {
        background: $grid-row-selection;
        @include border-radius-all(8px);
        font-weight: $font-weight-semibold;
        font-size: $text-lg;
        line-height: 1.5;
        color: $app-brand-primary;
        padding: 13px 20px;

        &:before {
          font-size: $text-md;
          font-weight: $font-weight-bold;
          color: $app-brand-primary;
          transform: rotate(270deg);
          transition: transform ease 0.3s;
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          margin: auto;
          float: none;
          width: 15px;
          height: 15px;
          line-height: normal;
          @include flexboxv2();
          @include align-itemsv2(center);
          @include justify-contentv2(center);
        }
      }
    }
  }
  &.m-l-accordion--plane {
    .m-l-accordion__header {
      .left-block {
        a {
          margin-left: 16px;
        }
        .m-l-port-info {
          display: inline-block;
          margin-top: 6px;
          margin-bottom: 6px;
        }
        .m-l-port-approval-info {
          display: inline-block;
          margin-top: 6px;
          margin-bottom: 6px;
          color: #f2994a;
          margin-left: 10px;
        }
        .m-l-port-approval-info-approved {
          display: inline-block;
          margin-top: 6px;
          margin-bottom: 6px;
          margin-left: 10px;
          color: #27ae60;
        }
        .m-l-port-warning {
          padding-left: 8px;
        }
      }
      .m-l-page-link {
        color: $app-brand-primary;
        font-size: $text-md;
        font-weight: $font-weight-semibold;
        text-decoration: underline;
        margin-left: 8px;
        cursor: pointer;
        &:hover {
          color: $app-primary-blue;
        }
      }
    }
    .dx-accordion {
      .dx-accordion-wrapper {
        .dx-accordion-item-title {
          background: $white;
          border: 1px solid $semi-gray;
        }
      }
    }
  }
}
.m-l-accordion-rules-tab {
  .dx-accordion {
    .dx-accordion-wrapper {
      .dx-accordion-item {
        // border-bottom: 1px solid $semi-gray;
      }
      .dx-accordion-item-title {
        padding-top: 9px;
        padding-bottom: 11px;
        // &:before {
        //   line-height: 44px;
        // }
      }
    }
  }
  .m-l-accordion__header {
    .left-block {
      a {
        margin-left: 16px;
      }
      .m-l-port-info {
        display: inline-block;
      }
    }
    .left-icon-block {
      margin-right: 8px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .m-l-page-link {
      color: $app-brand-primary;
      font-size: $text-md;
      font-weight: $font-weight-semibold;
      text-decoration: underline;
      margin-left: 8px;
      cursor: pointer;
      &:hover {
        color: $app-primary-blue;
      }
    }
  }
  .m-l-accordion-panel-body-block {
    padding: 8px;
  }
  .m-l-accordion__header {
    @include flexboxv2();
    @include align-itemsv2(center);
    .left-block {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
    }
    .right-block {
      margin-left: auto;
      @include flexboxv2();
      .app-c-btn {
        + .app-c-btn {
          margin-left: 16px;
          @media (max-width: 576px) {
            margin-left: 8px;
          }
        }
        &:last-child {
          margin-right: 14px;
          @media (max-width: 576px) {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .accordion-item__body-block {
    .m-c-rule-comment-row-wraper {
      @include flexboxv2();
      @include align-itemsv2(flex-start);
      .m-l-heading-h2 {
        color: $app-brand-primary;
        font-size: $text-xl-sm;
        font-weight: $font-weight-bold;
        margin-bottom: 16px;
      }
      .rule-details-block {
        border: 1px solid $semi-gray;
        @include border-radius-all(8px);
        @include flexboxv2();
        @include flex-directionv2(column);
        .message-list-block {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            @include flexboxv2();
            @include align-itemsv2(center);
            .message-content-block {
              h2 {
                color: $app-brand-primary;
                font-size: $text-md;
                font-weight: $font-weight-bold;
                margin-bottom: 0;
              }
              span {
                color: $plc-color;
                font-size: $text-md;
                font-weight: $font-weight-normal;

                &::before {
                  content: '';
                  width: 2px;
                  height: 2px;
                  @include border-radius-all(50%);
                }
              }
            }
          }
        }
      }
      .rule-details-body-wrap {
        padding: 16px;
        .rule-details {
          color: $app-brand-secondary;
          font-size: $text-md;
          font-weight: $font-weight-normal;
          margin-bottom: 0px;
        }
      }
      .rule-details-body-footer {
        margin-top: auto;
        padding: 16px 16px;
        @include flexboxv2();
        @include align-itemsv2(center);
        @include justify-contentv2(flex-end);
        .m-l-header-search-area-block {
          width: 100%;
          position: relative;
          .m-c-search-icon {
            position: absolute;
            top: 7px;
            right: 10px;
          }
        }
      }
      .left-block {
        width: 100%;
      }
      .right-block {
        width: 100%;
        margin-left: 16px;
      }
    }
  }
}
.accordion-item__body-il-block {
  padding-top: 25px;
}
// .m-c-accordion {
//   .m-l-inner-page-body {
//     margin-bottom: 0px;
//   }
// }
.m-l-accordion-page-title-block {
  h5 {
    color: $status-label;
    font-size: $text-lg;
    font-weight: $font-weight-normal;
    margin-bottom: 8px;
  }
}
.m-l-berthfit-check-accordion-section {
  .dx-accordion {
    .dx-accordion-wrapper {
      .dx-accordion-item {
        // border-bottom: 1px solid $semi-gray;
      }
      .dx-accordion-item-title {
        // padding-top: 9px;
        // padding-bottom: 11px;
        padding: 12px 16px;
        @include border-radius-all(8px);
        &:before {
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          margin: auto;
          float: none;
          width: 15px;
          height: 15px;
          line-height: normal;
          @include flexboxv2();
          @include align-itemsv2(center);
          @include justify-contentv2(center);
        }
      }
      .dx-accordion-item-body {
        padding-top: 0;
      }
      // .dx-accordion-item {
      //   &.dx-accordion-item-opened {
      //     .dx-accordion-item-title {
      //       &:before {
      //         top: -48px;
      //       }
      //     }
      //   }
      // }
    }
  }
  .m-l-accordion__header {
    @include flexboxv2();
    @include align-itemsv2(center);

    @media screen and (max-width: 767px) {
      @include align-itemsv2(flex-start);
      @include flex-directionv2(column);
    }
    .left-block {
      @include flexboxv2();
      @include align-itemsv2(center);
      @include flex-wrapv2(wrap);

      .title {
        color: $black;
        font-size: $text-lg;
        line-height: 1.5;
        font-weight: $font-weight-semibold;
        margin-bottom: 0;
        // max-width: 235px;
      }
      .m-l-view-info-link {
        margin-left: 16px;
        background-color: $grid-row-selection;
        padding: 2px 10px;
        line-height: 22px;
        @include border-radius-all(34px);
        span {
          color: $app-primary-blue;
          font-size: $text-md;
          font-weight: $font-weight-semibold;
        }
        &.m-l-view-info-link--web-view {
          display: inline-block;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
      .m-l-port-info {
        display: inline-block;
      }
    }
    .right-block {
      // width: 100%;
      margin-left: auto;
      margin-right: 32px;
      @include flexboxv2();
      @include align-itemsv2(center);
      @media screen and (max-width: 767px) {
        margin: 16px 0 0;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      @media screen and (max-width: 414px) {
        @include align-itemsv2(flex-start);
        @include flex-directionv2(column);
      }
      .m-l-view-info-link {
        margin-left: 16px;
        background-color: $grid-row-selection;
        padding: 2px 10px;
        line-height: 22px;
        @include border-radius-all(34px);
        span {
          color: $app-primary-blue;
          font-size: $text-md;
          font-weight: $font-weight-semibold;
        }
        &.m-l-view-info-link--mobile-view {
          display: inline-block;
          margin-left: auto;
          @media screen and (min-width: 767px) {
            display: none;
          }
          @media screen and (max-width: 414px) {
            margin-left: 0;
            margin-top: 8px;
          }
        }
      }
      .right-block-berthfit-id-date-wrap {
        @include flexboxv2();
      }
      .right-block-berthfit-id-block {
        margin-right: 16px;
        @include flexboxv2();
        @media screen and (max-width: 767px) {
          @include flex-directionv2(column);
        }
        @media screen and (max-width: 480px) {
          margin-right: 10px;
        }
        .id {
          display: inline-block;
          color: $status-label;
          font-size: $text-md-plus;
          font-weight: $font-weight-semibold;
          margin-right: 3px;
        }
        .value {
          display: inline-block;
          color: $app-brand-primary;
          font-size: $text-md-plus;
          font-weight: $font-weight-semibold;
        }
      }
      .right-block-berthfit-date-block {
        margin-left: 16px;
        position: relative;
        @include flexboxv2();
        @media screen and (max-width: 767px) {
          @include flex-directionv2(column);
        }
        @media screen and (max-width: 480px) {
          margin-left: 10px;
        }
        .id {
          display: inline-block;
          color: $status-label;
          font-size: $text-md-plus;
          font-weight: $font-weight-semibold;
          margin-right: 3px;
        }
        .value {
          display: inline-block;
          color: $app-brand-primary;
          font-size: $text-md-plus;
          font-weight: $font-weight-semibold;
        }
        &::before {
          content: '';
          height: 15px;
          width: 1px;
          background-color: #dadaf2;
          position: absolute;
          margin: 0px 16px;
          top: 3px;
          left: -32px;
          @media screen and (max-width: 767px) {
            height: 30px;
          }
          @media screen and (max-width: 480px) {
            left: -28px;
          }
        }
      }
    }
    .left-icon-block {
      margin-right: 8px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .m-l-page-link {
      color: $app-brand-primary;
      font-size: $text-md;
      font-weight: $font-weight-semibold;
      text-decoration: underline;
      margin-left: 8px;
      cursor: pointer;
      &:hover {
        color: $app-primary-blue;
      }
    }
  }
  &.open {
    .dx-accordion {
      .dx-accordion-wrapper {
        .dx-accordion-item {
          &.dx-accordion-item-opened {
            border: 1px solid $app-primary-blue;
            @include border-radius-all(8px);
            .dx-accordion-item-title {
              border-top: none;
              @include border-radius-all(0);
            }
            .m-l-berthfit-check-second-inner-accordion {
              .dx-accordion {
                .dx-accordion-wrapper {
                  .dx-accordion-item {
                    .dx-accordion-item-title {
                      @include border-radius-all(8px !important);
                    }
                    &.dx-accordion-item-opened {
                      .dx-accordion-item-title {
                        @include border-radius(8px, 8px, 0px, 0px !important);
                      }
                      .m-l-berthfit-check-third-inner-accordion {
                        .dx-accordion {
                          .dx-accordion-wrapper {
                            .dx-accordion-item-title {
                              @include border-radius-all(8px !important);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// 2nd inner accordion
.m-l-berthfit-check-second-inner-accordion {
  .dx-accordion {
    .dx-accordion-wrapper {
      .dx-accordion-item-title {
        background: $grid-row-selection !important;
        border: none !important;
        padding-top: 9px;
        padding-bottom: 11px;

        &:before {
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          margin: auto;
          float: none;
          width: 15px;
          height: 15px;
          line-height: normal;
          @include flexboxv2();
          @include align-itemsv2(center);
          @include justify-contentv2(center);

          @media screen and (max-width: 767px) {
            top: 2px;
          }
        }
      }
      .dx-accordion-item-body {
        padding: 0;
      }
    }
  }
  .m-l-accordion__header {
    @include flexboxv2();
    @include align-itemsv2(center);
    @media screen and (max-width: 767px) {
      @include align-itemsv2(flex-start);
      @include flex-directionv2(column);
    }
    .left-block {
      @include flexboxv2();
      @include align-itemsv2(center);

      @media screen and (max-width: 600px) {
        @include flex-wrapv2(wrap);
      }
      .title {
        color: $black;
        font-size: $text-lg;
        line-height: 1.5;
        font-weight: $font-weight-semibold;
        margin-bottom: 0;
      }
      .m-l-view-info-link {
        margin-left: 16px;
        background-color: $white;
        padding: 2px 10px;
        line-height: 22px;
        @include border-radius-all(34px);
        span {
          color: $app-primary-blue;
          font-size: $text-md;
          font-weight: $font-weight-semibold;
        }
        @media screen and (max-width: 600px) {
          margin-left: 0px !important;
          margin-top: 12px;
        }
      }
      .left-title-block {
        @media screen and (max-width: 600px) {
          width: calc(100% - 30px);
        }
      }
    }
  }
}
.m-l-berthfit-check-third-inner-accordion {
  .dx-accordion {
    .dx-accordion-wrapper {
      .dx-accordion-item-title {
        background: $white !important;
        border: 1px solid $semi-gray;
        padding-top: 9px;
        padding-bottom: 11px;
        &:before {
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          margin: auto;
          float: none;
          width: 15px;
          height: 15px;
          line-height: normal;
          @include flexboxv2();
          @include align-itemsv2(center);
          @include justify-contentv2(center);

          @media screen and (max-width: 767px) {
            top: 2px;
          }
        }
      }
      .dx-accordion-item-body {
        padding: 0;
      }
    }
  }
  .m-l-accordion__header {
    @include flexboxv2();
    @include align-itemsv2(center);
    @media screen and (max-width: 767px) {
      @include align-itemsv2(flex-start);
      @include flex-directionv2(column);
    }
    .left-block {
      @include flexboxv2();
      @include align-itemsv2(center);
      .title {
        color: $black;
        font-size: $text-lg;
        line-height: 1.5;
        font-weight: $font-weight-semibold;
        margin-bottom: 0;
      }
      .m-l-view-info-link {
        margin-left: 16px;
        background-color: $grid-row-selection;
        padding: 2px 10px;
        line-height: 22px;
        @include border-radius-all(34px);
        span {
          color: $app-primary-blue;
          font-size: $text-md;
          font-weight: $font-weight-semibold;
        }
      }
      .left-title-block {
        @include flexboxv2();
        .left-icon-block {
          margin-right: 8px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  // &.open {
  //   .dx-accordion {
  //     .dx-accordion-wrapper {
  //       .dx-accordion-item {
  //         &.dx-accordion-item-opened {
  //           border: 1px solid $app-primary-blue;
  //           @include border-radius-all(8px);
  //         }
  //       }
  //     }
  //   }
  // }
}

.m-l-accordion-inner-page-wrap {
  background-color: $grid-row-selection;
}
.m-l-accordion-inner-page-comment-row {
  padding: 0px 16px;
  border-bottom: 1px solid #c7d6eb;
}
.inner-comment-box-block {
}
.inner-comment-status-block {
  @media screen and (min-width: 991px) {
    padding-left: 0;
  }
}
.inner-comment-btn-block {
  @include flexboxv2();
  @include align-itemsv2(center);
  .app-c-btn {
    height: 35px;
    margin-top: 4px;
  }
  .mb-btm-space {
    @include flexboxv2();
    @include align-itemsv2(center);
    @include justify-contentv2(flex-end);
    @media screen and (min-width: 991px) {
      margin-bottom: 0 !important;
      @include justify-contentv2(flex-start);
    }
  }
  @media screen and (min-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.m-l-inner-first-accordion-section {
  background-color: $white;
  padding: 8px 8px 8px;
}
.m-l-inner-accordion__with-left-icons-wrap {
  padding: 16px 16px 8px;
}
.m-l-thried-level-accordion {
}
.m-l-third-layer-accordion-inner-page-wrap {
  background-color: $white;
}
.m-l-accordion-inner-page-grid-row {
  padding: 16px 16px 8px;
}
.main-top-level-accordion-bg {
  background-color: #f8faff;
}
