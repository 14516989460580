.app-c-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  height: $button-height;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size(6px, 16px, 14px, normal, 5px);
  font-family: $body-font;
  font-weight: $font-weight-semibold;
  color: $white;
  min-width: 72px; // Ammu 17/02/20
  line-height: 12px;
  padding: 5px 14px 5px !important;
  @include transition(all ease 0.3s);
  @include border-radius-all(4px);

  @media screen and (max-width: 600px) {
    min-width: auto;
    height: 38px;
    padding: 4px 14px !important;

    img {
      margin-right: 0px !important;
    }
  }

  &:hover,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: none;
      text-decoration: none;
      box-shadow: none;
    }
  }

  &:hover {
    color: $white;
    text-decoration: none;
    @include boxShadow(0 2px 5px rgba(6, 16, 28, 0.08) !important);
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none; // @include boxShadow(inset 0 3px 5px rgba(0,0,0,.125));
    @include noShadow();
  }

  &:focus,
  &.focus {
    @include noShadow();
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled !important;
    opacity: 0.65;
    @include boxShadow(none);
    pointer-events: none;

    &:hover {
      @include boxShadow(none);
    }
  }

  // [converter] extracted a& to a.btn
  + .app-c-btn {
    margin-left: 8px;
    // @media screen and (max-width: 600px){
    //   margin: 10px 0 0 0;
    // }
  }

  img {
    margin-right: 10px;
  }

  &.app-c-btn-lg-h {
    height: 40px;
  }
  .dx-button-content {
    @include flexboxv2();
    @include align-itemsv2(center);
    @include justify-contentv2(center);
  }
}

.app-c-btn--primary {
  color: $white !important;
  text-transform: capitalize !important;
  background: $app-primary-blue !important;
  border-color: $app-primary-blue !important;

  &:hover {
    background: darken($app-primary-blue, 6%) !important;
    border-color: $app-primary-blue !important;
    outline: none !important;
    @include transition(all ease 0.3s);
    @include boxShadow(0px 1px 5px #ccc !important);
  }

  &:active,
  &.active {
    // @include noShadow();
    @include boxShadow(0px 1px 5px #ccc !important);
    outline: none !important;
  }

  &:focus,
  &.focus {
    // @include noShadow();
    @include boxShadow(0px 1px 5px #ccc !important);
    background: darken($app-primary-blue, 6%) !important;
    outline: none !important;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    position: relative;
    font-size: $text-sm-plus;
    left: -4px;
  }
}

.m-c-status-icon-btn {
  padding-left: 8px;
  display: inline-block;

  svg {
    width: 12px;
    height: 15px;
  }
}

.app-c-btn--secondary {
  background: $white !important;
  color: $app-primary-blue !important;
  text-transform: capitalize !important;
  border-color: $app-primary-blue !important;
  border: 1.5px solid $app-primary-blue !important;

  &:hover,
  &:focus,
  &:active {
    color: $text-color;
    background: $white !important;
    // color: $secondary-btn-color !important;
    // border-color: #848A90 !important;
    border-color: $app-primary-blue !important;
    outline: none !important;
    color: $app-primary-blue !important;
    border: 1.5px solid $app-primary-blue !important;
    @include boxShadow(0px 1px 5px #ccc !important);
  }

  [class^='icon-'],
  [class*=' icon-'] {
    position: relative;
    font-size: $text-sm-plus;
    left: -4px;
  }

  &.disabled {
    color: $secondary-btn-color;
  }
  .m-c-icon-btn {
    padding-right: 8px;
  }
  .dx-button-content {
    padding: 0;
  }
}

.app-c-btn--remove {
  // width: 48px;
  // height: 40px;
  width: 36px;
  height: 36px;
  background: $white !important;
  color: $secondary-red !important;
  text-transform: capitalize !important;
  border-color: $secondary-red !important;
  border: 1.5px solid $secondary-red !important;

  &:hover,
  &:focus,
  &:active {
    color: $text-color;
    background: $white !important;
    // color: $secondary-btn-color !important;
    // border-color: #848A90 !important;
    border-color: $secondary-red !important;
    outline: none !important;
    color: $secondary-red !important;
    border: 1.5px solid $secondary-red !important;
    @include boxShadow(0px 1px 5px #ccc !important);
  }

  [class^='icon-'],
  [class*=' icon-'] {
    position: relative;
    font-size: $text-sm-plus;
    left: -4px;
  }

  &.disabled {
    color: $secondary-btn-color;
  }
  .dx-button-content {
    padding: 0px !important;
  }
  svg {
    width: 20px;
  }
}
.app-c-icon-only-btn {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  padding: 5px !important;
  background: $white !important;
  border-color: transparent !important;
  border: 1.5px solid transparent !important;
  box-shadow: none !important;
  &:hover,
  &:focus,
  &:active {
    border-color: transparent !important;
    background-color: $grid-row-selection !important;
    outline: none !important;
    border: 1.5px solid transparent !important;
    box-shadow: none;
    // @include boxShadow(0px 1px 5px #ccc !important);
  }

  [class^='icon-'],
  [class*=' icon-'] {
    position: relative;
    font-size: $text-sm-plus;
    left: -4px;
  }

  &.disabled {
    color: $secondary-btn-color;
  }
  .dx-button-content {
    padding: 0px !important;
  }
}
.min-btn-width {
  min-width: 120px !important;
}
.min-btn-width-small {
  min-width: 27px !important;
  min-height: 27px !important;
  width: 27px !important;
  height: 27px !important;
  padding: 0px !important;
  .dx-button-content {
    padding: 0px !important;
  }
  svg {
    width: 18px;
    height: 18px;
  }
}
.m-c-icon-inside-btn {
  @include flexboxv2();
  @include align-itemsv2(center);
  svg {
    width: 22px;
    height: 22px;
  }
}
.m-c-icon-inside-secondary-button {
  min-width: 36px !important;
  min-height: $button-height !important;
  width: 36px !important;
  height: $button-height !important;
  .dx-button-content {
    padding: 0;
  }
  svg {
    width: 18px;
    height: 18px;
  }
}
.min-btn-width-sm-pls {
  // min-width: 48px !important;
  // width: 48px !important;
  // height: 40px;
  min-width: 36px !important;
  width: 36px !important;
  height: 36px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(center);
  .dx-button-content {
    padding: 0px !important;
  }
  svg {
    width: 20px;
  }
}
.m-l-accodion-icon-bg {
  width: 36px;
  height: 37px;
  min-width: 36px;
  min-height: 37px;
  padding: 8px !important;
  background: $white !important;
  border-color: transparent !important;
  border: 1.5px solid $white !important;
  box-shadow: none;
  @include border-radius-all(50%);
  &:hover,
  &:focus,
  &:active {
    border-color: transparent !important;
    background: $white !important;
    outline: none !important;
    border: 1.5px solid $white !important;
    box-shadow: none;
  }
  .dx-button-content {
    padding: 0px;
  }
}
.m-l-icon-btn-holder {
  svg {
    width: 18px;
    height: 18px;
  }
}
.app-c-btn--tertiary {
  background: $white !important;
  color: $app-primary-blue !important;
  text-transform: capitalize !important;
  border-color: $white !important;
  border: 1.5px solid $white !important;

  &:hover,
  &:focus,
  &:active {
    color: $text-color;
    background: $white !important;
    // color: $secondary-btn-color !important;
    // border-color: #848A90 !important;
    border-color: $app-primary-blue !important;
    outline: none !important;
    color: $app-primary-blue !important;
    border: 1.5px solid $white !important;
    @include boxShadow(0px 1px 5px #ccc !important);
  }

  [class^='icon-'],
  [class*=' icon-'] {
    position: relative;
    font-size: $text-sm-plus;
    left: -4px;
  }

  &.disabled {
    color: $secondary-btn-color;
  }
  .m-c-icon-btn {
    padding-right: 8px;
  }
  .dx-button-content {
    padding: 0;
  }
  .dx-button-content {
    @include flexboxv2();
    @include align-itemsv2(center);
    @include justify-contentv2(flex-start);
  }
  .m-c-icon-inside-btn {
    padding-right: 8px;
  }
  svg {
    width: 18px;
    height: 18px;
  }
}

.m-l-btn--icon-plain {
  width: 17px;
  height: 17px;
  border: none;
  padding: 0;
  background: none;

  .dx-button-content {
    padding: 0;
  }
  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: $dark-blue;
    }
  }
}
