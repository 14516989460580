.m-c-grid {
  height: 100%;
  @media screen and (max-width: 767px) {
    height: auto;
  }
  .dx-invalid-message > .dx-overlay-content {
    top: 2px !important;
  }
  .dx-datagrid {
    background: transparent;
  }
  .dx-widget {
    // height: 100%;

    .dx-datagrid {
      .dx-datagrid-header-panel {
        .dx-toolbar {
          display: none;
        }
      }
      .dx-datagrid-headers {
        // border-top-color: $semi-gray;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom: none;

        .dx-datagrid-content {
          @include border-radius(10px, 10px, 0px, 0px);
          .dx-datagrid-table {
            tbody {
              .dx-row {
                .custom-filter-column {
                  .dx-menu {
                    display: none !important;
                  }
                  &.dx-editor-cell {
                    .dx-editor-with-menu {
                      .dx-editor-container {
                        .dx-texteditor {
                          .dx-texteditor-input-container {
                            .dx-texteditor-input {
                              padding-left: 0 !important;
                              padding-right: 0 !important;
                              border: 0 !important;
                            }
                          }
                        }
                      }
                    }
                  }
                  // .dx-texteditor-input {
                  // }

                  .m-c-input-control {
                    .dx-texteditor-input-container {
                      height: 37px;
                    }
                    .dx-placeholder::before {
                      color: #333 !important;
                      padding: 6px 10px 5px;
                    }
                  }
                }
                td {
                  background: $white;
                  padding: 8px;
                  padding-left: 8px;
                  padding-right: 8px;
                  line-height: normal;
                  border-color: transparent;
                  color: $app-brand-secondary;
                  font-size: 12px;
                  font-weight: 600;
                  .dx-column-indicators {
                    .dx-header-filter {
                      color: $app-brand-secondary;

                      @include transition(color ease 0.2s);
                      &.dx-header-filter-empty {
                        color: #b6b7b9;
                      }
                    }
                  }
                  &.dx-command-select {
                    .dx-select-checkbox {
                      position: absolute;
                      top: 44px;
                      left: 15px;
                      z-index: 1;
                    }
                  }
                  &.dx-editor-cell {
                    .dx-editor-with-menu {
                      .dx-editor-container {
                        .dx-texteditor {
                          .dx-texteditor-input-container {
                            .dx-texteditor-input {
                              padding-left: 8px !important;
                              padding-right: 32px !important;
                              border: 1px solid $app-secondary-border-color;
                            }
                          }
                        }
                      }
                    }
                  }
                  &:last-child {
                    padding-right: 8px;
                  }
                  &:first-child {
                    padding-left: 8px;
                  }
                  .dx-selectbox {
                    .dx-dropdowneditor-icon {
                      &:before {
                        content: '\f016';
                        font-size: $text-lg;
                        color: $dark-blue;
                        margin-top: -8px;
                      }
                    }
                  }
                  .dx-datagrid-text-content {
                    color: $status-label;
                    font-size: $text-md;
                    font-weight: 500;
                  }
                }

                &.dx-datagrid-filter-row {
                  td {
                    padding-top: 0;
                    padding-bottom: 15px;
                    .dx-editor-container {
                      overflow: visible;

                      .dx-textbox,
                      .dx-numberbox {
                        .dx-texteditor-container {
                          overflow: visible;
                        }
                      }
                      .dx-dropdowneditor {
                        margin-left: 0;
                        border: 1px solid $app-secondary-border-color;
                        @include border-radius-all(4px);
                        &.dx-datebox {
                          .dx-texteditor-container {
                            .dx-texteditor-input-container {
                              .dx-texteditor-input {
                                border-color: transparent !important;
                                padding-left: 34px !important;
                              }
                            }
                            .dx-texteditor-buttons-container {
                              position: absolute;
                              top: 50%;
                              transform: translateY(-50%);
                              .dx-dropdowneditor-button {
                                border-color: transparent;
                                .dx-dropdowneditor-icon {
                                  border: none !important;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .dx-filter-menu {
                      // display: none;
                      top: 0;
                      left: auto;
                      right: 0;
                      .dx-menu-items-container {
                        .dx-menu-item-wrapper {
                          .dx-menu-item {
                            .dx-menu-item-content {
                              .dx-icon {
                                color: $dark-blue;
                                font-size: 16px;
                              }
                            }
                          }
                        }
                      }
                    }

                    .dx-menu {
                      height: calc(100% - 1px);
                      border: 1px solid transparent;
                      border-top-right-radius: 2px;
                      border-bottom-right-radius: 2px;
                      top: 2px;
                      right: -0.5px;
                      ul.dx-menu-items-container {
                        height: 100%;
                        li.dx-menu-item-wrapper {
                          height: 100%;
                          .dx-item {
                            .dx-menu-item-content {
                              padding: 6px 7px 7px;
                              height: 100%;
                              vertical-align: bottom;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &.dx-column-lines {
                  td {
                    border-left: none;
                    border-right: none;
                  }
                  &.dx-header-row {
                    border-style: hidden;
                    td {
                      padding-bottom: 4px;
                    }
                  }
                  // .dx-datagrid-action {
                  //   padding-bottom: 0px;
                  // }
                }
                &.grid-row-disabled {
                  opacity: 0.4;
                  pointer-events: none;
                }
              }
            }
          }
          .dx-datagrid-focus-overlay {
            display: none;
          }
        }
      }
      .dx-datagrid-rowsview {
        border-color: $semi-gray;
        background-color: #fff;
        border-left: 1px solid #ededed;
        border-right: 1px solid #ededed;
        // overflow: auto;
        height: 100%;
        border-bottom-color: transparent;
        // height: 600px;

        .dx-datagrid-content {
          .dx-datagrid-table {
            .dx-row {
              border-bottom-color: $semi-gray;
              cursor: pointer;
              td {
                padding: 10px 12px;
                vertical-align: middle;
                color: $app-brand-secondary;
                font-size: 12px;
                line-height: normal;
                font-weight: 400;
                //for remove trimming white space
                white-space: pre;
                @include transition(background ease 0.2s);
                border-left-color: transparent;
                border-right-color: transparent;
                border-left: none;
                border-right: none;

                &.dx-command-edit-with-icons {
                  vertical-align: middle;

                  .dx-link {
                    // font-family: $app-fonts;
                    color: $app-brand-secondary;
                    font-size: 16px;

                    @include transition(color ease 0.2s);

                    &.dx-link-edit {
                      &:before {
                        content: '\e91f';
                      }
                    }

                    &:hover {
                      color: #3b4458;
                    }
                  }
                }
                .app-c-grid-actions {
                  @include flexboxv2();
                  @include align-itemsv2(center);
                  @include justify-contentv2(center);

                  .m-l-btn--icon-plain {
                    + .m-l-btn--icon-plain {
                      margin-left: 16px;
                    }
                  }
                }
                &.status-active {
                  color: $sucess;
                  font-weight: $font-weight-semibold;
                  // border-right: 2px solid $sucess;
                }
                &.status-inactive {
                  color: $red;
                  font-weight: $font-weight-semibold;
                  // border-right: 2px solid $red;
                }
                &.status-pending {
                  color: $secondary-brown;
                  font-weight: $font-weight-semibold;
                  // border-right: 2px solid $secondary-brown;
                }
                &.status-active-wtout {
                  color: $sucess;
                  font-weight: $font-weight-semibold;
                }
                &.status-inactive-wtout {
                  color: $red;
                  font-weight: $font-weight-semibold;
                }
                &.status-pending-wtout {
                  color: $secondary-brown;
                  font-weight: $font-weight-semibold;
                }
                &.status-wrong-entry-wtout {
                  color: $gray-500;
                  font-weight: $font-weight-semibold;
                }
                &:last-child {
                  padding-right: 8px;
                  // border-top: none !important;
                }
                &:first-child {
                  padding-left: 8px;
                }
              }
              + .dx-row {
                td {
                  border-top: 1px solid $semi-gray;
                }
              }
              &:hover,
              &.dx-selection {
                td {
                  background: $grid-row-selection;
                  font-weight: 600;
                  .m-l-badge-container {
                    .badge {
                      font-weight: 600;
                    }
                  }
                  .m-l-field-wrap {
                    .m-l-field-labels {
                      font-weight: 600;
                    }
                  }
                  .m-l-grid-delete-icon-wrapper {
                    svg {
                      fill: #96b1d7;
                    }
                  }
                }
              }
              &.dx-freespace-row {
                td {
                  background: $white;
                }
              }
              &:hover {
                td {
                  background: #e8f1fd;
                }
              }
              &.grid-row-disabled {
                opacity: 0.4;
              }
            }
          }
        }
        // .dx-scrollable-container {
        //   .dx-scrollable-scrollbar {
        //     .dx-scrollable-scroll {
        //       height: 11px !important;
        //       &.dx-state-invisible {
        //         opacity: 1 !important;
        //         .dx-scrollable-scroll-content {
        //           // background-color: rgba(
        //           //   $color: #174A84,
        //           //   $alpha: 0.1
        //           // ) !important;
        //           background-color: $app-brand-primary !important;
        //           @include border-radius-all(20px);
        //         }
        //       }
        //     }
        //     &.dx-scrollbar-horizontal {
        //       &.dx-scrollbar-hoverable {
        //         height: 11px !important;
        //         &.dx-state-hover,
        //         &.dx-scrollable-scrollbar-active {
        //           height: 11px !important;
        //           background-color: $grid-row-selection !important;
        //         }
        //         .dx-scrollable-scroll {
        //           .dx-scrollable-scroll-content {
        //             background-color: $app-brand-primary !important;
        //             height: 11px !important;
        //             @include border-radius-all(20px);
        //           }
        //         }
        //       }
        //     }
        //     &.dx-scrollbar-hoverable &.dx-state-hover {
        //       .dx-scrollable-scroll {
        //         height: 11px !important;
        //       }
        //     }
        //   }
        // }
      }
    }

    &.dx-selection-disabled {
      .dx-datagrid-rowsview {
        .dx-selection {
          &.dx-row {
            &:not(.dx-row-focused) {
              > td {
                background-color: rgba(247, 249, 251, 0.72);
                border-bottom: none;
                border-top: none;
              }
            }
            + .dx-row {
              &:not(.dx-row-focused) {
                > td {
                  border-top: 1px solid rgba(#e2e4ec, 0.8);
                }
              }
            }
          }
        }
      }
    }
    &.m-l-no-filter-row-in-grid {
      .dx-datagrid-headers
        .dx-datagrid-content
        .dx-datagrid-table
        tbody
        .dx-row.dx-column-lines.dx-header-row
        td {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }
  .dx-datagrid-focus-overlay {
    border: none;
  }
  .dx-command-select {
    background-color: #f8f9fb;
  }
  .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
    width: 35px;
    min-width: 35px;
    max-width: 35px;
    padding: 5px 5px !important;
  }
  .dx-datagrid .dx-column-lines > td.dx-command-select {
    border-right: 1px solid transparent !important;
  }
  .dx-datagrid-rowsview
    .dx-select-checkboxes-hidden
    > tbody
    > tr
    > td
    > .dx-select-checkbox {
    display: block;
  }
  .dx-datagrid-checkbox-size {
    line-height: normal;
  }
  .dx-checkbox-container {
    height: 18px;
    width: 18px;
  }
  .dx-datagrid-checkbox-size .dx-checkbox-icon {
    @include border-radius-all(3px);
    border: 2px solid $dark-blue;
    width: 18px;
    height: 18px;
    &::before {
      font-size: 11px;
      color: $dark-blue;
      line-height: 17px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0px;
      margin-left: 0px;
    }
  }
  // .dx-checkbox-checked .dx-checkbox-icon {
  //     border-color: #43bb80;
  //     background: #43bb80;
  // }
  .dx-datagrid-pager {
    padding: 12px 16px !important;
    border: none;
    margin: 0px !important;
    border-bottom: 1px solid $semi-gray;
    border-left: 1px solid $semi-gray;
    border-right: 1px solid $semi-gray;
    @include border-radius(0px, 0px, 8px, 8px);
    background-color: #fff;
    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  .dx-pager {
    .dx-pages {
      @include flexboxv2();
      @include align-itemsv2(center);
      // @media (max-width: 1024px) {
      //   position: relative;
      //   left: -50%;
      //   transform: translateX(50%);
      // }

      .dx-info {
        color: $app-brand-primary;
        font-size: 12px;
        font-weight: 500;
        margin-right: 8px !important;
        opacity: 1 !important;

        @media (max-width: 480px) {
          display: none;
        }
      }
    }
  }
  .dx-pager {
    .dx-pages {
      .dx-page-indexes {
        @include flexboxv2();
        @include align-itemsv2(center);
        .dx-navigate-button {
          font-size: 16px;
          color: $app-brand-primary;
          padding: 6px 10px;
        }
        .dx-page {
          margin-right: 5px;
        }
      }
    }
  }
  .dx-pager .dx-page-sizes .dx-selection,
  .dx-pager .dx-pages .dx-page {
    //   @include border-radius(3px);
    padding: 2px 8px;
    line-height: normal;
    color: $app-brand-secondary;
    font-size: 12px;
    font-weight: 500;
    &.dx-selection {
      color: #fff;
      background-color: $app-brand-primary;
    }
  }

  &.wrapped-rows {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          .dx-datagrid-content {
            .dx-datagrid-table {
              .dx-row {
                td {
                  white-space: normal !important;
                  vertical-align: top !important;
                }
              }
            }
          }
        }
      }
    }
  }
  &.m-l-vsh-grid {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          height: auto;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          @media screen and (max-width: 991px) {
            max-height: 100%;
          }
        }
        .dx-datagrid-headers {
          border-top-color: $semi-gray;
          border-left-color: $semi-gray;
          border-right-color: $semi-gray;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }
  }
  &.m-l-grid-with--top-border {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-headers {
          border-top-color: $semi-gray;
          border-left-color: $semi-gray;
          border-right-color: $semi-gray;
          @include border-radius(8px, 8px, 0px, 0px);
        }
      }
    }
  }
  .dx-widget {
    .dx-datagrid {
      .dx-datagrid-headers {
        position: relative;
        .dx-datagrid-content {
          .dx-datagrid-table {
            tbody {
              .dx-row.dx-column-lines {
                td {
                  background: $grd-bg;
                  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06);
                }
              }
            }
          }
        }
        .dx-datagrid-text-content {
          // .app-c-grid-header-icon-btn {
          //   position: absolute;
          //   right: 24px;
          //   top: 50%;
          //   transform: translateY(-50%);
          // }
        }
        .dx-header-row {
          .dx-datagrid-action {
            .dx-column-indicators {
              margin-top: 3px;
            }
            .dx-sort-up::before {
              content: '\f013';
              color: $dark-blue;
            }
            .dx-sort-down::before {
              content: '\f015';
              color: $dark-blue;
            }
          }

          .dx-editor-container {
            .dx-texteditor-container {
              border: 1px solid $app-secondary-border-color;
              @include border-radius-all(4px);
            }
          }
        }
      }
    }
  }

  .dx-datagrid-table {
    .dx-data-row {
      td:last-child {
        // min-height: 38px;
        // @include flexboxv2();
        .app-c-btn {
          margin-left: auto;
          height: 30px;
          @include border-radius-all(5px);
          min-width: 76px;
          padding: 6px !important;
        }
      }
    }
  }
  .dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
    border-bottom-width: 1px;
    position: relative;
  }
  &.m-c-righticon-grid {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          .dx-datagrid-content {
            .dx-datagrid-table {
              .dx-row {
                td {
                  &.dx-command-edit-with-icons {
                    // @include flexboxv2();
                    // width: 100%;
                    .dx-link-icon {
                      margin-left: auto;
                      cursor: pointer;
                      color: $dark-blue;
                      @include transition(color ease 0.2s);
                      &:hover {
                        color: $semi-black;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.m-c-grid__role {
    .dx-datagrid-text-content {
      #add {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
  .m-l-no-filter-row-in-grid {
    .dx-datagrid-headers {
      .dx-datagrid-content {
        .dx-datagrid-table {
          tbody {
            .dx-row {
              td {
                &.dx-command-select {
                  .dx-select-checkbox {
                    top: 12px !important;
                    left: 13px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .m-l-no-filter-row-in-grid {
    .dx-datagrid-rowsview {
      .dx-datagrid-content {
        .dx-datagrid-table {
          tbody {
            .dx-row {
              td {
                position: relative;
                &.dx-command-select {
                  .dx-select-checkbox {
                    position: absolute;
                    top: 12px !important;
                    left: 13px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.m-c-grid-in-tab {
    .dx-datagrid-pager {
      border-left: none !important;
      border-right: none !important;
      border-bottom: none !important;
      border-top: 1px solid $semi-gray;
      padding-bottom: 11px !important;
    }
  }

  &.full-border-checkbox-grid {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-headers {
          border-top-color: $semi-gray;
          border-left-color: $semi-gray;
          border-right-color: $semi-gray;
          border-bottom: none;
          @include border-radius(4px, 4px, 0px, 0px);
          .dx-datagrid-content {
            .dx-datagrid-table {
              tbody {
                .dx-row {
                  &.dx-column-lines {
                    &.dx-header-row {
                      td {
                        padding-top: 16px;
                        padding-bottom: 16px;
                      }
                    }
                  }
                  td {
                    &.dx-command-select {
                      .dx-select-checkbox {
                        top: 15px !important;
                        left: 10px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .dx-datagrid-rowsview {
          .dx-datagrid-content {
            .dx-datagrid-table {
              .dx-row {
                td {
                  &:first-child {
                    background-color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.datagrid-pager-no-border-bottom {
    .dx-datagrid-pager {
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }
  }
}
// Grid clone popup
.page-inner-wrap {
  .m-c-grid {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-headers {
          border-top-color: $semi-gray;
          border-left-color: $semi-gray;
          border-right-color: $semi-gray;
          @include border-radius(8px, 8px, 0px, 0px);
        }
      }
    }
  }
}

.dx-popover-wrapper {
  .dx-overlay-content {
    // overflow: hidden;
    .dx-popup-content {
      // min-height: 300px !important;
      @include flexboxv2();
      @include border-radius-all(8px !important);
      overflow: auto;
      height: 100% !important;
    }
  }
  .dx-popup-normal {
    width: 302px !important;
    padding: 0px !important;
    max-height: 522px !important;
    @include border-radius-all(4px !important);
    @include boxShadow(0px 0px 8px rgba(0, 0, 0, 0.05));
    .dx-popover-arrow {
      &::after {
        display: none;
      }
    }
  }
}
.m-l-add-roles__popup {
  .dx-popover-wrapper {
    .dx-overlay-content {
      .dx-popup-content {
        min-height: 300px !important;
      }
    }
  }
}

.m-l-grid-clone-popup-wrap {
  width: 300px;
  max-height: 350px;
  // position: absolute;
  // top: 140px;
  // right: 50px;
  background-color: $white;
  z-index: 3;
  @include border-radius-all(4px);
  @include flexboxv2();
  @include flex-directionv2(column);
  .m-l-grid-clone-popup-header {
    padding: 12px 16px 0px 16px;
    @include flex-shrinkv2(0);

    h2 {
      font-size: $text-lg;
      font-weight: $font-weight-bold;
      color: $app-brand-secondary;
      margin-bottom: 10px;
    }

    &.m-l-grid-copy--popup-header {
      @include flexboxv2();
      @include align-itemsv2(center);
      h2 {
        margin-bottom: 0px;
      }
    }
    .m-l-header-close__icon-block {
      margin-left: auto;
      .app-c-icon-only-btn {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        padding: 3px;
      }
    }
  }
  .m-l-grid-clone-popup-body {
    padding: 16px 16px 16px;
    overflow: auto;
  }
  .m-l-grid-clone-popup-footer {
    padding: 16px 16px 16px;
    @include flexboxv2();
    @include align-itemsv2(center);
    @include justify-contentv2(flex-end);
  }
}
.m-l-add-roles__popup {
  .m-l-grid-clone-popup-wrap {
    .m-l-grid-clone-popup-body {
      padding-bottom: 0px;
    }
  }
}
.m-l-popover-search-box-block {
  position: relative;
  // margin-top: 8px;
  .m-c-search-icon {
    position: absolute;
    top: 9px;
    left: 10px;
    svg {
      width: 16px;
      path {
        fill: $plc-color;
      }
    }
  }
}
.m-l-checkbox-list-wrap {
  list-style: none;
  margin: 0;
  padding: 12px 0px 0px;
  li {
    padding: 8px 16px;
    &:hover,
    &.active {
      background-color: $grid-row-selection;
    }
    .m-c-checkbox {
      .dx-checkbox {
        .dx-checkbox-container {
          .dx-checkbox-text {
            font-weight: $font-weight-semibold;
            font-size: $text-md;
            line-height: 1.71;
            color: $app-brand-secondary;
            padding-left: 8px;
          }
        }
      }
    }
  }
}

//grid new
.m-c-grid {
  &.m-c-userlist-grid {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          height: auto;
          max-height: 100%;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          @include border-radius-all(0px);
          @media screen and (max-width: 991px) {
            max-height: 100%;
          }
          .dx-datagrid-content {
            .dx-datagrid-table {
              .dx-row {
                td {
                  padding: 16px;
                }
                .dx-command-select {
                  width: 48px;
                  min-width: 48px;
                  max-width: 48px;
                }
              }
            }
          }
        }
        .dx-datagrid-headers {
          border-top-color: $semi-gray;
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
    }
    &.m-c-chk-btn-grid {
      .dx-widget {
        .dx-datagrid {
          .dx-datagrid-rowsview {
            .dx-datagrid-content {
              .dx-datagrid-table {
                .dx-row {
                  td {
                    padding: 13px 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.m-c-grid-in-tab {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          border-left: none;
          border-right: none;
          border-bottom: none;
          @include border-radius-all(0px);
          // height: 57vh;
          .dx-datagrid-table {
            .dx-row:nth-last-child(2) {
              // border-bottom-color: $semi-gray;
              border-bottom-color: transparent;
            }
          }
        }
        .dx-datagrid-headers {
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
    }
    &.no-border-top {
      .dx-widget {
        .dx-datagrid {
          .dx-datagrid-headers {
            border-top-color: transparent;
          }
        }
      }
    }
    &.no-border-bottom-row {
      .dx-widget {
        .dx-datagrid {
          .dx-datagrid-rowsview {
            .dx-datagrid-table {
              .dx-row:nth-last-child {
                border-bottom-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  &.m-c-grid-fixed--data-height {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          height: 57vh;
        }
      }
    }
  }
  &.m-c-grid-with-no-outerborder {
    padding-bottom: 12px;
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-rowsview {
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
        }
        .dx-datagrid-headers {
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
    }
    .dx-datagrid-rowsview {
      .dx-datagrid-content {
        .dx-datagrid-table {
          .dx-row {
            &:nth-last-child(2) {
              border-bottom: none;
            }
          }
        }
      }
    }
    &.m-c-btm-no-border {
      .dx-datagrid-rowsview {
        border-bottom-color: transparent;
      }
    }
  }
}

.dx-menu-item.dx-state-hover {
  background-color: $hover-bg;
  .m-l-grid-delete-icon-wrapper {
    svg {
      fill: #96b1d7;
    }
  }
}
.app-c-grid-header-icon-btn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 48px !important;
  width: 48px !important;
}
.app-c-grid-header-add-icon-btn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.dx-filter-menu {
  .dx-submenu {
    @include boxShadow(0px 0px 8px rgba(0, 0, 0, 0.05));
    @include border-radius-all(4px);
    .dx-menu-items-container {
      @include boxShadow(0px 0px 8px rgba(0, 0, 0, 0.05));
      .dx-menu-item-wrapper {
        .dx-menu-item {
          &.dx-menu-item-selected {
            background-color: $grid-row-selection;
          }
          .dx-menu-item-content {
            padding: 10px 16px 7px;
            @include flexboxv2();
            @include align-itemsv2(center);
            .dx-icon {
              width: 20px;
              height: 20px;
              color: $app-brand-primary;
              @include flexboxv2();
              @include align-itemsv2(center);
            }
            .dx-menu-item-text {
              padding: 3px 25px 3px 26px;
              font-size: $text-lg;
              font-weight: $font-weight-normal;
              color: $app-brand-secondary;
              font-family: $font-primary;
            }
          }
        }
        // .dx-menu-item-selected {
        //   background-color: $grid-row-selection;
        // }
      }
    }
  }
}

.m-l-grid-wrapper-block {
  @include border-radius-all(8px);
  border: 1px solid $semi-gray;
  min-height: 39vh;
  &.m-l-appl-history-blck {
    min-height: auto;
    height: 100%;

    .m-l-no-filter-row-in-grid.dx-widget {
      height: 100%;
    }
    .m-l-with-filter-row-in-grid.dx-widget {
      height: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: auto;
  }
}
.m-c-grid-mb-auto-height {
  @media screen and (max-width: 767px) {
    height: auto;
  }
}
.m-l-grid-active-link {
  font-size: $text-md-plus;
  color: $app-primary-blue;
  text-decoration: underline;
}
.delete-icon-wrap {
  width: 100%;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-end);
}
.m-l-management-page-grid-action-button {
  width: 100%;
  margin-bottom: 8px;
  cursor: pointer;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-end);
}

.m-l-icon-btn-holder {
  @include flexboxv2();
  @include align-itemsv2(center);
  margin-left: auto;
  .m-l-grid-delete-icon-wrapper {
    margin-left: 15px;
  }
}

.m-l-report-toggle-btn-holder {
  @include flexboxv2();
  @include align-itemsv2(center);
  margin-left: auto;
  .m-l-report-toggle-btn {
    padding: 8px 8px 0px 0px;
  }
}

.m-c-auto-scroll-grid {
  .m-l-no-filter-row-in-grid.dx-widget {
    height: 100%;
    max-height: inherit;
  }
  .m-l-with-filter-row-in-grid.dx-widget {
    height: 100%;
    max-height: inherit;
  }
  @media screen and (max-width: 767px) {
    height: 100%;
  }
}
.m-l-file-icon-wrapper {
  width: 18px;
  height: 18px;
}
.m-l-incident-grid {
  max-height: 350px;
}
.m-l-grid-top-border-wrapper-block {
  .m-c-grid {
    .dx-widget {
      .dx-datagrid {
        .dx-datagrid-headers {
          border-top-color: $semi-gray;
          border-left-color: $semi-gray;
          border-right-color: $semi-gray;
          @include border-radius(8px, 8px, 0px, 0px);
        }
      }
    }
  }
}

.m-l-dashboard__chart-export {
  .dx-popup-normal {
    max-width: 132px !important;
    background-color: $white;

    .ml-expport-list {
      padding: 10px 0;
      width: 100%;
      margin: 0;

      li {
        list-style: none;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        padding: 7px 10px;
        color: $app-brand-secondary;
        cursor: pointer;
        font-family: $body-font;

        @include transition(background ease 0.2s);

        &:hover {
          background-color: $grid-row-selection;
        }
      }
    }
  }
}

.m-c-grid--company {
  max-height: calc(100vh - 127px);

  > .dx-widget {
    height: 100%;
  }
}

.dx-datagrid .dx-icon-filter-operation-default:before,
.dx-datagrid-container .dx-icon-filter-operation-default:before {
  content: url('../../images/filterIcon.svg');
}
