// -------- Brand --------
$app-brand-primary: #174a84;
$app-brand-secondary: #585c64;
$app-primary-blue: #2f80ed;
$app-primary-blue-light: #0056a0;

//
//
// -------- Grays --------
$gray-000: #fafbfc !default;
$gray-100: #f7f8f9 !default;
$gray-150: #f5f5f5 !default;
$gray-200: #e1e4e8 !default;
$gray-250: #dfdfdf !default;
$gray-300: #d1d5da !default;
$gray-400: #959da5 !default;
$gray-500: #6a737d !default;
$gray-600: #586069 !default;
$gray-700: #444d56 !default;
$gray-750: #414346 !default;
$gray-800: #2f363d !default;
$gray-900: #24292e !default; // body font color

// -------- Blue --------
$blue-000: #f1f8ff !default;
$blue-100: #dbedff !default;
$blue-200: #c8e1ff !default;
$blue-300: #79b8ff !default;
$blue-400: #2188ff !default;
$blue-500: #0366d6 !default;
$blue-600: #005cc5 !default;
$blue-700: #044289 !default;
$blue-800: #032f62 !default;
$blue-900: #05264c !default;

// -------- Green --------
$green-000: #f3f8f1 !default;
$green-100: #f7fafc !default;
$green-200: #bef5cb !default;
$green-300: #85e89d !default;
$green-400: #34d058 !default;
$green-500: #28a745 !default;
$green-600: #22863a !default;
$green-700: #176f2c !default;
$green-800: #165c26 !default;
$green-900: #144620 !default;

// -------- Yellow --------
$yellow-000: #fffdef !default;
$yellow-100: #fffbdd !default;
$yellow-200: #fff5b1 !default;
$yellow-300: #ffea7f !default;
$yellow-400: #ffdf5d !default;
$yellow-500: #ffd33d !default;
$yellow-600: #f9c513 !default;
$yellow-700: #dbab09 !default;
$yellow-800: #b08800 !default;
$yellow-900: #735c0f !default;

// -------- Orange --------
$orange-000: #fff8f2 !default;
$orange-100: #ffebda !default;
$orange-200: #ffd1ac !default;
$orange-300: #ffab70 !default;
$orange-400: #fb8532 !default;
$orange-500: #f66a0a !default;
$orange-600: #e36209 !default;
$orange-700: #d15704 !default;
$orange-800: #c24e00 !default;
$orange-900: #a04100 !default;

// -------- Red --------
$red-000: #ffeef0 !default;
$red-100: #ffdce0 !default;
$red-200: #fdaeb7 !default;
$red-300: #f97583 !default;
$red-400: #ea4a5a !default;
$red-500: #d73a49 !default;
$red-600: #cb2431 !default;
$red-700: #b31d28 !default;
$red-800: #9e1c23 !default;
$red-900: #86181d !default;

// -------- Purple --------
$purple-000: #f5f0ff !default;
$purple-100: #e6dcfd !default;
$purple-200: #d1bcf9 !default;
$purple-300: #b392f0 !default;
$purple-400: #8a63d2 !default;
$purple-500: #6f42c1 !default;
$purple-600: #5a32a3 !default;
$purple-700: #4c2889 !default;
$purple-800: #3a1d6e !default;
$purple-900: #29134e !default;

// -------- Fades --------
// Black based on same hue as $gray-900
$black: #1b1f23 !default;
$semi-black: #06101c;
$dark-blue: #2d5179;
$light-blue: #51a2ff;
$semi-gray: #e5e5e5;
$white: #fff !default;
$red: #df2f2f !default;
$sucess: #00b050 !default;
$text-color: #4f4f4f;
$text-color-200: #100c10;
$text-secondary: #585c64;
$pagination-text-color: #51626f;
$plc-color: #90a1b5;
$placeholder-color: #b1bdcb;
$secondary-brown: #d27420;
$grid-row-selection: #ebf3fe;
$secondary-red: #eb5757;
$tab-bg-color: #ebf4ff;
$radio-btn-border: #c2cdd9;
$radio-btn-bg: #f0f7fe;
$toastSuccessBg: #ecf6e7;
$toastWarnningBg: #f8eeda;
$toastErrorBg: #f9e4e6;

$black-fade-15: rgba($black, 0.15) !default;
$black-fade-30: rgba($black, 0.3) !default;
$black-fade-50: rgba($black, 0.5) !default;
$black-fade-70: rgba($black, 0.7) !default;
$black-fade-85: rgba($black, 0.85) !default;

$white-fade-15: rgba($white, 0.15) !default;
$white-fade-30: rgba($white, 0.3) !default;
$white-fade-50: rgba($white, 0.5) !default;
$white-fade-70: rgba($white, 0.7) !default;
$white-fade-85: rgba($white, 0.85) !default;

// -------- Color defaults --------
$red: $red-500 !default;
$purple: $purple-500 !default;
$blue: $blue-500 !default;
$green: $green-500 !default;
$yellow: $yellow-500 !default;
$orange: $orange-500 !default;

$gray-dark: $gray-900 !default;
$gray-light: $gray-400 !default;
$gray: $gray-500 !default;

$body-color: #efeff4;
$secondary-btn-color: #3c4258;
$app-secondary-border-color: #90a1b5;
$app-tertiary-border-color: #dfdfe1;
$notification-border: #e8e8ed;
$white-btn-bg: #e5fae9;
$white-btn-pg-bg: #f7fafc;
$grd-bg: #fcfcfd;
$thumbclr: #cacadb;
$green-sw: #27ae60;
$bt-bg-color: #deedff;
$gray-lighter: #ccc;
$hover-bg: #e8f1fd;
$border-color-txtarea: #e6e6e6;
$orange-st: #f2994a;
$status-label: #828282;
$extra-dark: #342325;
$bd-color3: #eae0e1;
