.dx-tooltip-wrapper {
  .dx-popup-normal {
    &.dx-overlay-content {
      background-color: $app-brand-primary !important;
      width: auto !important;
      height: auto !important;
      padding: 4px 12px !important;
      color: $white;
      border-color: $app-brand-primary;
      @include border-radius-all(12px);
    }
    .dx-popup-content {
      @include border-radius-all(0px!important);
      font-size: $text-md;
      font-weight: $font-weight-normal;
      line-height: 1;
      overflow: hidden;
      white-space: normal;
      max-width: 208px;
      border-color: $app-brand-primary;
    }
  }
  .m-l-info-tooltip {
    padding-top: 8px;
    line-height: 1.33;
    padding-bottom: 8px;
    text-align: left;
  }
  &.dx-popover-wrapper {
    .dx-popover-arrow {
      &:after {
        content: '';
        display: inline-block;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background-color: $app-brand-primary;
        border-color: $app-brand-primary;
      }
    }
  }
}
.m-l-tooltip-wrapper {
  &.m-l-warning-icon-tooltip {
    @include flexboxv2();
    @include flex-directionv2(column);
    span {
      margin-bottom: 5px;
    }
  }
}
