.m-c-checkbox {
  .dx-checkbox {
    .dx-checkbox-container {
      .dx-checkbox-icon {
        border: 1.5px solid $app-secondary-border-color;
        width: 18px;
        height: 18px;
        @include border-radius-all(3px);
      }
      .dx-checkbox-text {
        font-weight: $font-weight-semibold;
        font-size: $text-lg;
        line-height: 1.71;
        color: $app-secondary-border-color;
        padding-left: 8px;
        white-space: normal;
        word-break: break-word;
      }
    }
    &.dx-checkbox-checked {
      .dx-checkbox-icon {
        &:before {
          margin-top: 0px;
          margin-left: 0px;
          transform: translate(-50%, -50%);
          color: $app-secondary-border-color;
          font-size: $text-md;
        }
      }
    }
  }
  &.m-c-checkbox-dark {
    .dx-checkbox {
      .dx-checkbox-container {
        .dx-checkbox-icon {
          border: 2px solid $dark-blue;
        }
      }
      &.dx-checkbox-checked {
        .dx-checkbox-icon {
          &:before {
            color: $dark-blue;
          }
        }
      }
    }
  }
  &.m-c-checkbox-normal {
    .dx-checkbox {
      .dx-checkbox-container {
        .dx-checkbox-text {
          color: $text-color;
          font-size: $text-md;
          font-weight: 600;
        }
      }
    }
  }
  &.m-c-checkbox--round {
    .dx-checkbox {
      .dx-checkbox-container {
        .dx-checkbox-icon {
          background-color: $radio-btn-bg;
          border: 1px solid $radio-btn-border;
          width: 16px;
          height: 16px;
          @include border-radius-all(50%);
          &:before {
            content: '' !important;
            width: 8px;
            height: 8px;
            background: $light-blue;
            @include border-radius-all(50%);
          }
        }
      }
    }
  }
}
.m-l-default-checkbox-unit {
  .m-c-checkbox {
    .dx-checkbox {
      .dx-checkbox-container {
        .dx-checkbox-icon {
          border: 1.5px solid $app-brand-secondary;
          width: 12px;
          height: 12px;
          @include border-radius-all(3px);
        }
      }
      &.dx-checkbox-checked {
        .dx-checkbox-icon {
          &:before {
            font-size: 8px;
            color: $dark-blue;
            margin-top: 0.1px;
            margin-left: -0.2px;
          }
        }
      }
    }
  }
}
