.m-c-tab-block {
  height: 100%;
  &.dx-tabpanel {
    .dx-tabpanel-tabs {
      // width: 60%;
      width: calc(100% - 338px);
      overflow: auto;
      // @media screen and (max-width: 891px) {
      //   width: 50%;
      // }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .dx-tabs {
        position: relative;
        box-shadow: none !important;
        background-color: transparent;
        // border-bottom: 1px solid $semi-gray;

        .dx-tabs-nav-button {
          border: none;
          background: none;

          @include boxShadow(none);
        }
        .dx-tabs-wrapper {
          .dx-tab {
            width: auto;
            // padding: 24px 16px 14px;
            padding: 8px 0px;
            background: transparent;
            box-shadow: none !important;
            display: inline-block;
            // &:before {
            //   content: '';
            //   width: 0;
            //   height: 3px;
            //   position: absolute;
            //   bottom: -3px;
            //   left: 0;
            //   right: 0;
            //   margin: auto;
            //   background: $app-primary-blue;

            //   @include transition(width ease 0.2s);
            // }
            .dx-tab-content {
              padding: 8px 16px 8px;
              background: none !important;
              .dx-tab-text {
                font-size: $text-lg;
                font-weight: 500;
                color: $app-brand-secondary;
                text-transform: capitalize;
                line-height: normal;
              }
            }
            &.dx-tab-selected {
              box-shadow: none;
              background: $tab-bg-color;
              @include border-radius(0px, 0px, 0px, 0px);
              // &:before {
              //   width: 100%;
              // }
              .dx-tab-content {
                background: $white;
                .dx-tab-text {
                  color: $app-primary-blue;
                }
              }
            }
            &:first-child {
              padding-left: 8px;
              .dx-tab-content {
                @include border-radius(4px, 0px, 0px, 4px);
              }
            }
            &:last-child {
              padding-right: 8px;
              .dx-tab-content {
                @include border-radius(0px, 4px, 4px, 0px);
              }
            }
          }
        }
        &.dx-state-focused {
          box-shadow: none !important;
          .dx-tabs-wrapper {
            .dx-tab {
              box-shadow: none;
            }
          }
        }
      }
      // .MuiTab-root {
      //   font-size: $text-lg;
      //   font-weight: 500;
      //   color: $app-brand-secondary;
      //   text-transform: capitalize;
      //   padding: 28px 15px 18px;
      //   &.Mui-selected {
      //     color: $app-primary-blue;
      //   }
      // }
    }
    .dx-tabpanel-container {
      .dx-multiview-wrapper {
        border: none;
        border-top: 1px solid $semi-gray;
      }
    }
    &.m-c-tab-ibox {
      .dx-tabpanel-tabs {
        .dx-tabs {
          .dx-tabs-wrapper {
            .dx-tab {
              font-size: $text-md;
              padding-top: 8px;
              padding-bottom: 14px;
            }
          }
        }
      }
    }
    &.m-c-tab-eml {
      .dx-tabpanel-tabs {
        .dx-tabs {
          .dx-tabs-wrapper {
            .dx-tab {
              .dx-tab-content {
                .dx-tab-text {
                  font-size: $text-md;
                }
              }
            }
          }
        }
      }
    }
  }
  .MuiTabs-indicator {
    background-color: $app-primary-blue;
  }
  &.m-c-tab-block--full-width {
    &.dx-tabpanel {
      .dx-tabpanel-tabs {
        width: 100% !important;
        overflow: auto;
      }
    }
  }
  &.m-c-tab-in-lg {
    &.dx-tabpanel {
      .dx-tabpanel-tabs {
        width: calc(100% - 400px);
        overflow: auto;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
  &.m-c-tab--half-width {
    height: 100%;
    &.dx-tabpanel {
      .dx-tabpanel-tabs {
        width: calc(100% - 562px);
        overflow: auto;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
.m-l-notification-list-block {
  .m-l-notification-list-block-body {
    .m-c-tab-block {
      height: 100%;
      &.dx-tabpanel {
        .dx-tabpanel-tabs {
          .dx-tabs {
            .dx-tabs-wrapper {
              .dx-tab {
                width: auto;
                // padding: 24px 16px 14px;
                padding: 8px 8px 0px;
              }
            }
          }
        }
      }
    }
  }
}

.m-l-panel__show {
  .m-c-tab-block.dx-tabpanel {
    &.m-c-appl-lg {
      .dx-tabpanel-tabs {
        .dx-tabs {
          .dx-tabs-wrapper {
            .dx-tab {
              .dx-tab-content {
                .dx-tab-text {
                  font-weight: $font-weight-extrabold;
                  font-size: $text-xl-sm;
                  line-height: 1.33;
                }
              }
              &.dx-tab-selected .dx-tab-content .dx-tab-text {
                color: $app-brand-primary;
              }
            }
          }
        }
      }
      .dx-tabpanel-tabs {
        overflow: hidden;
      }
      .dx-item {
        float: left;
      }
    }
  }
}
// .m-l-tab-accordion-item-content-section {
//   padding: 8px 20px;
// }

// custom vertical tab

.m-l-vertical-tab-block {
  list-style: none;
  margin: 0;
  padding: 16px 0px;
  background: $tab-bg-color;
  min-width: 234px;
  height: 100%;
  li {
    cursor: pointer;
    padding: 14px 8px;
    @include flexboxv2();
    @include align-itemsv2(center);
    p {
      font-size: $text-lg;
      font-weight: 500;
      color: $app-brand-secondary;
      text-transform: capitalize;
      line-height: normal;
      text-align: left;
      margin-bottom: 0;
    }
    &.tab-selected {
      color: $app-primary-blue;
      background-color: $white;
    }
    .btn-right-block {
      margin-left: auto;
      .app-c-btn {
        width: 35px;
        height: 36px;
        min-width: 35px;
        min-height: 36px;
        padding: 8px !important;
        background: transparent !important;
        border-color: transparent !important;
        box-shadow: none;
        &:hover,
        &:focus,
        &:active {
          border-color: transparent !important;
          box-shadow: none !important;
        }
        svg {
          width: 18px;
          height: 18px;
        }
        .dx-button-content {
          padding: 0;
        }
      }
    }
  }
}
