#dropzone-external {
  width: 100%;
  height: 107px;
  // border: 1px dashed $app-secondary-border-color;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2390A1B5FF' stroke-width='2' stroke-dasharray='4 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  @include border-radius-all(8px);
  padding: 10px;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}
.widget-container {
  position: relative;
  width: 100%;
  height: 107px;
  & > span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .m-l-file-cntr-box {
    position: absolute;
  }
  .dx-fileuploader-wrapper {
    opacity: 0;
    padding: 0px;
    .dx-fileuploader-container {
      .dx-fileuploader-content {
        .dx-fileuploader-input-wrapper {
          .dx-button {
            width: 100%;
            height: 107px;
          }
        }
      }
    }
  }
  .dx-fileuploader-input-label {
    display: none;
  }
  .dx-fileuploader-files-container {
    display: none;
  }
}

#dropzone-text > span {
  font-weight: $font-weight-semibold;
  font-size: $text-xl;
  line-height: 1.5;
  color: $app-brand-secondary;
  @media (max-width: 360px) {
    font-size: $text-lg;
  }
}

#upload-progress {
  @include flexboxv2();
  margin-top: 10px;
  width: 100% !important;
}

.m-l-file-upload-custom-flex-box {
  @include flexboxv2();
  @include justify-contentv2(center);
  @include align-itemsv2(center);
}
.m-file-browse {
  color: $app-primary-blue;
  padding-left: 4px;
  cursor: pointer;
}

.m-l-uploaded-file-datas {
  padding-top: 0px;
  // height: 167px;
  // overflow: auto;
}
.m-l-input-fileicon-wrap {
  position: relative;
  .dx-texteditor-input-container .dx-texteditor-input {
    color: $app-brand-secondary;
    padding: 6px 50% 6px 8px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}
.m-l-slcted-file-wrapper {
  margin-top: 32px;
}
.m-l-hide-note {
  display: none;
}
