//dropdown
.dx-dropdownbutton-popup-wrapper {
  &.dx-popup-wrapper {
    .dx-popup-normal {
      padding: 0px;
      .dx-dropdownbutton-content .dx-list .dx-list-item {
        font-weight: $font-weight-normal;
        font-size: $text-md;
        line-height: 1.33;
        color: $app-brand-secondary;
        .dx-list-item-content {
          padding: 10px 16px;
        }
        &.dx-state-hover {
          background-color: $grid-row-selection;
          color: $app-primary-blue;
        }
      }
    }
  }
}
.m-l-vsl-dropdown-btn {
  .dx-dropdownbutton {
    min-width: 134px;
    min-height: 28px;
    position: relative;
    .dx-button-has-text {
      .dx-button-content {
        padding: 3px 8px 3px;
        .dx-button-text {
          font-weight: $font-weight-semibold;
          font-size: $text-lg;
          line-height: 1.71;
          color: $app-brand-secondary;
        }
      }
    }
    .dx-icon-spindown::before {
      content: '\f016';
      font-size: $text-lg;
      color: $dark-blue;
      font-weight: $font-weight-bold;
      line-height: 1.3;
      position: absolute;
      right: 12px;
    }
    .dx-button {
      border: 1px solid $grid-row-selection;
      @include border-radius-all(4px);
    }
    .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-hover,
    .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-focused {
      background-color: $grid-row-selection;
    }
  }
  &.m-l-gray-dropdown-wiout-border {
    .dx-dropdownbutton {
      min-width: auto;
      .dx-button-has-text {
        .dx-button-content {
          .dx-button-text {
            color: rgba($app-brand-secondary, 0.5);
          }
        }
        .dx-icon.dx-icon-right {
          margin-left: 26px;
        }
      }
    }
    .dx-button {
      border: 1px solid transparent;
    }
    .dx-icon-spindown::before {
      color: rgba($dark-blue, 0.5);
    }
    &.dropdown-inside-grid {
      .dx-button {
        border: 1px solid $semi-gray;
      }
      .dx-dropdownbutton {
        width: 100%;

        .dx-button-has-text {
          .dx-button-content {
            @include flexboxv2();
            @include justify-contentv2(space-between);
            @include align-itemsv2(center);
            .dx-button-text {
              color: $app-brand-secondary;
              font-weight: $font-weight-normal;
            }
          }
        }
      }
      .dx-icon-spindown::before {
        color: rgba($app-brand-secondary, 0.8);
        right: 7px;
        top: 8px;
      }
    }
  }
  &.m-l-dropdown-with-noarrow {
    .dx-dropdownbutton {
      min-width: auto;
      .dx-button-has-text {
        .dx-button-content {
          padding: 2px 16px;
          .dx-button-text {
          }
        }
      }
    }
    .dx-button {
      // border: 1px solid transparent;
    }
    .dx-icon-spindown {
      display: none;
    }
  }
}

.m-l-status-drp-dwn {
  &.orange {
    &.dx-dropdownbutton .dx-button-has-text .dx-button-content .dx-button-text {
      color: $orange-st;
    }
  }
}
.m-c-grid {
  .m-l-vsl-dropdown-btn {
    .dx-dropdownbutton {
      .dx-button-has-text {
        .dx-button-content {
          .dx-button-text {
            font-weight: $font-weight-semibold;
            font-size: $text-md;
            line-height: 1.71;
            color: $app-brand-secondary;
          }
        }
      }
    }
  }
}
