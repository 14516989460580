.m-c-radio-button {
  .dx-radiogroup {
    .dx-radiobutton {
      .dx-radio-value-container {
        padding-right: 8px;
        .dx-radiobutton-icon {
          height: 14px;
          &::before {
            width: 12px;
            height: 12px;
            border: 2px solid $dark-blue;
          }
        }
      }
      .dx-item-content {
        color: $text-color;
        font-size: $text-md;
        font-weight: $font-weight-bold;
        margin-left: -6px;
        margin-top: 2px;
      }
      &.dx-radiobutton-checked {
        .dx-radio-value-container {
          .dx-radiobutton-icon {
            &.dx-radiobutton-icon-checked {
              .dx-radiobutton-icon-dot {
                margin-top: -14px;
                margin-left: 6px;
                width: 8px;
                height: 8px;
                background: $dark-blue;
                position: relative;
                top: 2px;
                right: 2px;
              }
            }
          }
        }
      }
    }
  }
  &.m-c-radio-button--light {
    .dx-radiogroup {
      .dx-radiobutton {
        .dx-radio-value-container {
          padding-right: 0px;
          .dx-radiobutton-icon {
            width: 14px;
            height: 14px;
            &::before {
              width: 13px;
              height: 13px;
              background-color: $radio-btn-bg;
              border: 1px solid $radio-btn-border;
            }
          }
        }
        &.dx-radiobutton-checked {
          .dx-radio-value-container {
            .dx-radiobutton-icon {
              // background-color: $white;
              &.dx-radiobutton-icon-checked {
                .dx-radiobutton-icon-dot {
                  margin-top: -14px;
                  margin-left: 6px;
                  width: 8px;
                  height: 8px;
                  min-width: 8px;
                  min-height: 8px;
                  background: $light-blue;
                  position: relative;
                  top: 2.3px;
                  right: 2.6px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.m-c-custom-space-radiobtn {
    .dx-radiogroup-horizontal .dx-radiobutton {
      margin-right: 36px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
