@import './../../assets/scss/variables/variables';
@import './../../assets/scss/theme/common-color-system';
@import './../../assets/scss/theme/themes/theme1';
@import './../../assets/scss/core/mixins/flexbox-v2';
@import './../../assets/scss/core/mixins/border-radius';
@import './../../assets/scss/core/mixins/transitions';

.m-c-report__filter {
  @include flexboxv2();

  @media screen and (max-width: 767px) {
    @include flex-directionv2(column);
  }
  .m-c-report__f-left {
    width: 380px;
    max-width: 50%;

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 16px;
    }

    .m-c-form__inline {
      @include align-itemsv2(flex-start);

      .m-c-form__icon {
        @include flexboxv2();
        @include flex-directionv2(column);

        .m-c-icon-inside-btn {
          + .m-c-icon-inside-btn {
            margin-top: 8px;
          }
        }
      }
    }
  }
  .m-c-report__f-right {
    padding-left: 8px;
    min-width: 0;
    width: 100%;
    max-width: 450px;

    @media screen and (max-width: 767px) {
      padding-left: 0;
      max-width: 100%;
    }
    .m-c-form-group {
      height: 100%;

      @include flexboxv2();
      @include flex-directionv2(column);

      .m-c-input-control {
        height: 100%;
      }
    }
  }
}
.m-l-report-avatar {
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(center);
}
.m-l-no-info {
  display: block;
  text-align: center;
}

.m-l-result-layout__dropdown {
  .dx-popup-normal {
    max-width: 132px !important;
    background-color: $white;

    .ml-expport-list {
      padding: 10px 0;
      width: 100%;
      margin: 0;

      li {
        list-style: none;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        padding: 7px 10px;
        color: $app-brand-secondary;
        cursor: pointer;
        font-family: $body-font;

        @include transition(background ease 0.2s);

        &:hover {
          background-color: $grid-row-selection;
        }
      }
    }
  }
}

.m-l-selected__filter {
  border: $app-secondary-border-color 1px solid;
  height: 100%;
  min-height: 0;

  @include border-radius(4px, 4px, 4px, 4px);

  .dx-scrollable {
    max-height: 159px;

    @include flexboxv2();
    @include flex-directionv2(column);

    .dx-scrollable-wrapper {
      min-height: 0;

      @include flexboxv2();
      @include flex-directionv2(column);
    }
  }
  .dx-scrollview-content {
    .dx-item {
      .dx-item-content {
        .m-l-select_filter-row {
          padding-right: 8px;

          @include flexboxv2();
          @include align-itemsv2(center);

          .m-l-select__left {
            min-width: 0;
            .m-l-select__text {
              white-space: normal;
              font-size: 13px;
            }
          }
          .m-l-select__right {
            margin-left: auto;

            @include flex-shrinkv2(0);
          }
        }
      }
      &.dx-state-focused {
        background: #e8f1fd;
        color: inherit;
      }
    }
  }
}
.m-l-inner-page-footer {
  .m-c-form__inline {
    margin-right: 8px;

    @media screen and (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}
