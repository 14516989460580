.m-c-text-editor {
  height: 100%;
  overflow: auto;
  &.dx-htmleditor {
    border-color: $app-secondary-border-color;
    &.dx-htmleditor-outlined {
      &.dx-state-active {
        border-color: $app-secondary-border-color;
      }
      &.dx-state-focused {
        border-color: $app-secondary-border-color;
      }
    }
    .dx-htmleditor-toolbar-format {
      &.dx-dropdowneditor {
        &.dx-editor-filled {
          height: 28px;
          border-color: $semi-gray;
        }
        .dx-dropdowneditor-input-wrapper {
          height: 24px;
          .dx-texteditor-input-container {
            .dx-texteditor-input {
              font-size: $text-sm-plus;
              min-height: 20px;
            }
            .dx-placeholder {
              height: 24px;
              &:before {
                font-size: $text-sm-plus;
                padding-top: 4px;
                padding-bottom: 4px;
              }
            }
          }
        }
      }
    }
    .dx-texteditor {
      &.dx-editor-outlined {
        border-color: $app-secondary-border-color;
      }
    }
    .dx-htmleditor-toolbar {
      .dx-button-mode-text {
        &.dx-button-normal {
          &.dx-state-hover,
          &.dx-state-active {
            background-color: $grid-row-selection;
          }
        }
      }
      .dx-format-active:not(.dx-color-format):not(.dx-background-format) {
        &.dx-button-mode-text {
          &.dx-button-normal {
            background-color: transparent;
            .dx-icon {
              color: $app-brand-secondary;
            }
          }
        }
      }
      .dx-button-mode-text {
        .dx-icon {
          color: $app-secondary-border-color;
          font-size: 12px;
          font-weight: 400;
        }
        &.dx-bold-format {
          .dx-icon {
            color: $text-secondary;
            font-weight: 700;
          }
        }
      }
    }
    .dx-toolbar-button {
      .dx-button {
        &.dx-state-hover {
          background-color: $grid-row-selection;
        }
      }
    }
    .dx-htmleditor-toolbar-wrapper {
      .dx-toolbar-after {
        .dx-button-mode-contained {
          height: 28px;
          border-color: $semi-gray;
          .dx-button-content {
            padding: 4px;
          }
        }
      }
    }
  }
  .dx-list:not(.dx-list-select-decorator-enabled)
    .dx-list-item.dx-state-focused {
    background-color: $grid-row-selection;
  }

  .dx-dropdowneditor {
    &.dx-dropdowneditor-active {
      .dx-dropdowneditor-icon {
        background-color: $grid-row-selection;
      }
    }
    .dx-state-hover:not(.dx-custom-button-hovered) {
      .dx-dropdowneditor-icon {
        background-color: $grid-row-selection !important;
      }
    }
  }
  &.m-c-text-editor-error-border {
    border: 1px solid $red;
  }
}
.m-c-text-editor-error-text {
  color: $red;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 1.33;
  top: 4px !important;
  z-index: 10 !important;
}
.m-c-html-editor-wrapper {
  height: calc(100% - 18px);
  .dx-quill-container {
    .dx-htmleditor-content {
      p {
        font-size: $text-md;
        font-weight: $font-weight-normal;
        color: $app-brand-secondary;
        margin: 0;
      }
    }
  }
  .dx-dropdownmenu-popup-wrapper {
    .dx-popup-normal {
      width: 40% !important;

      @media screen and (max-width: 767px) {
        width: auto !important;
      }
      .dx-dropdownmenu-list {
        width: 100%;
      }
    }
  }
  // .m-c-mail-text-editor {
  //   .dx-invalid-message {
  //     position: relative !important;
  //     .dx-invalid-message-content {
  //       position: absolute;
  //       top: -260px !important;
  //       left: 16px !important;
  //     }
  //   }
  // }
}
.m-c-flex-fill {
  flex: 1 1 auto !important;
  overflow: auto;
  min-height: 200px;
}

.dx-popup-wrapper {
  &.dx-formdialog {
    .dx-popup-normal {
      max-height: 100% !important;
      max-width: 100% !important;
      padding: 22px 28px !important;
      .dx-formdialog-form {
        .dx-layout-manager {
          .dx-item-content {
            .dx-field-item {
              @include flex-directionv2(column);
            }
          }
        }
      }
      .dx-popup-title.dx-toolbar {
        padding: 0px;
        min-height: auto;
        border-bottom: none;
        .dx-toolbar-label {
          font-weight: $font-weight-extrabold;
          font-size: $text-xl-sm;
          line-height: 1.33;
          color: $app-brand-primary;
          padding-bottom: 32px;
          max-width: 100% !important;
        }
      }
      .dx-popup-bottom.dx-toolbar {
        padding: 20px 0px 0px 0px;
        .dx-toolbar-items-container {
          .dx-toolbar-after {
            .dx-item.dx-toolbar-item.dx-toolbar-button {
              .dx-item-content {
                .dx-button {
                  height: 35px;
                  text-transform: capitalize;
                  border-color: $app-primary-blue;
                }
              }
              &:first-child {
                .dx-item-content {
                  .dx-button {
                    color: $white;
                    background-color: $app-primary-blue;
                    &:hover {
                      background: darken($app-primary-blue, 6%);
                      border-color: $app-primary-blue;
                      @include transition(all ease 0.3s);
                      @include boxShadow(0px 1px 5px #ccc !important);
                    }
                  }
                }
              }
              &:nth-child(2) {
                .dx-item-content {
                  .dx-button {
                    color: $app-primary-blue;
                    &:hover {
                      @include boxShadow(0px 1px 5px #ccc);
                      background-color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .dx-layout-manager {
        .dx-box-item {
          .dx-field-item-content {
            .dx-texteditor.dx-editor-outlined {
              border-color: $app-secondary-border-color;
              height: 35px;
            }
          }
        }
        .dx-field-item-label {
          color: $text-color;
          font-size: $text-md;
          font-weight: $font-weight-bold;
          margin-bottom: 4px;
        }
        .dx-field-item:not(.dx-first-row) {
          padding-top: 18px;
        }
      }
    }
    .dx-formdialog-form {
      @media (max-width: 480px) {
        min-width: 100%;
      }
    }
  }
}
.dx-htmleditor {
  .dx-htmleditor-toolbar-wrapper {
    padding: 0px 8px;
    border-bottom: none !important;
    .dx-toolbar-items-container {
      height: 44px;
      .dx-item {
        .dx-item-content {
          .dx-dropdowneditor {
            height: 24px;
            border-color: #e5e5e5;
            &.dx-state-hover,
            &.dx-state-active {
              .dx-dropdowneditor-icon {
                background-color: $grid-row-selection;
              }
            }
            .dx-dropdowneditor-input-wrapper {
              height: 24px;
              .dx-texteditor-container {
                .dx-texteditor-input {
                  min-height: 24px;
                  font-size: $text-sm-plus;
                }
                .dx-placeholder {
                  &:before {
                    font-size: $text-sm-plus;
                    line-height: 10px;
                  }
                }
                .dx-texteditor-buttons-container {
                  width: 22px;
                  height: 23px;
                  .dx-dropdowneditor-button {
                    width: 22px;
                    min-width: 24px;
                  }
                }
              }
            }
          }
          .dx-button {
            .dx-button-content {
              padding: 3px !important;
            }
          }
        }
        &.app-l-font-class {
          .dx-item-content {
            .dx-dropdowneditor {
              width: 90px;
              @include border-radius-all(4px);
            }
          }
        }
        &.app-l-font-size-class {
          .dx-item-content {
            .dx-dropdowneditor {
              width: 62px;
              @include border-radius-all(4px);
            }
          }
        }
        &.app-l-filter-dropdown {
          .dx-item-content {
            .dx-dropdowneditor {
              width: 150px;
              min-width: 150px;
              @include border-radius-all(4px);
            }
          }
        }
      }
    }
    .dx-htmleditor-toolbar-separator {
      height: 24px;
      margin-left: 2px;
      margin-right: 2px;
    }
    .dx-dropdownmenu-popup-wrapper {
      .dx-popup-normal {
        .dx-dropdownmenu-list {
          .dx-toolbar-menu-section {
            padding-bottom: 8px;
            @media screen and (max-width: 1024px) {
              padding-bottom: 25px;
            }
            .dx-button-content {
              .dx-icon {
                color: $app-brand-secondary;
                font-size: $text-lg;
              }
              .dx-button-text {
                font-size: $text-lg;
                font-weight: 400;
                color: $app-brand-secondary;
              }
            }
            .app-l-filter-dropdown {
              .dx-selectbox {
                border-color: $semi-gray;
              }
            }
          }
        }
      }
    }
  }
}
