.m-c-bredcrumb {
  margin: 0;
  padding: 0;
  @include flexboxv2();
  @include align-itemsv2(center);
  margin-bottom: 8px;
  display: none;
  li {
    @include flexboxv2();
    @include align-itemsv2(center);
    a {
      padding: 0px;
      color: $app-brand-secondary !important;
      font-size: $text-md;
      font-weight: 400;
      text-decoration: none;
      cursor: pointer;
      &.active {
        color: $app-primary-blue !important;
      }
      &:hover {
        color: $base-font-color;
        &.active {
          color: darken($app-brand-primary, 5%);
          text-decoration: underline;
        }
      }
    }
    &:before {
      content: '>';
      padding: 0 6px;
      color: $app-brand-secondary;
      font-size: $text-md;
    }
    &:first-child {
      &:before {
        display: none !important;
      }
    }
  }
}
.MuiBreadcrumbs-separator {
  display: none !important;
}
