.m-c-sidebar {
  position: fixed;
  width: 62px;
  min-width: 62px;
  box-shadow: 0 2px 4px $lt-grey-4;
  background: $app-brand-primary;
  color: $white;
  height: 100%;
  z-index: 5;
  @include transition(all ease 0.4s);
  // @media screen and (max-width: 767px) {
  //   max-width: 62px;
  //   min-width: 62px;
  // }
  // @media screen and (max-width: 891px) {
  //   @include transform(translateX(-75px));
  // }
  // .m-l-sidebar-arrow-button {
  //   position: absolute;
  //   top: 46px;
  //   right: -25px;
  //   display: none;

  //   @media screen and (max-width: 480px) {
  //     display: block;
  //   }
  // }
  // &.sidebar-open {
  //   @include transform(translateX(0px));
  //   @include transition(all ease 0.4s);
  // }
}
.dx-drawer {
  .dx-drawer-wrapper {
    .dx-drawer-panel-content {
      width: 62px !important;
    }
  }
}
.m-l-sidebar-logo-block {
  padding: 16px 12px 22px 12px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(center);
}
.m-l-sidebar-icons-wrap {
  width: 62px !important;
  padding: 0px 8px !important;
  height: calc(100% - 52px);
  .dx-scrollable-wrapper {
    .dx-scrollable-container {
      .dx-scrollview-content {
        .dx-item {
          margin-bottom: 8px;
          border-top: 1px solid transparent;
          &.dx-item-focused,
          &.dx-item-selected,
          &.dx-state-hover,
          &.dx-list-item-selected,
          &.dx-state-active {
            background-color: $app-primary-blue;
            @include border-radius-all(6px);
          }
          .dx-item-content {
            // padding: 12px 18px 18px 20px;
            @include flexboxv2();
            @include align-itemsv2(center);
            @include justify-contentv2(center);
            position: relative;
            .dx-list-item-icon-container {
              @include flexboxv2();
              @include align-itemsv2(center);
              @include justify-contentv2(center);
            }
          }
        }
      }
    }
  }
  // .MuiListItem-root {
  //     padding: 13px 18px;
  //     margin-bottom: 8px;
  //     @include flexboxv2();
  //     @include align-itemsv2(center);
  //     @include justify-contentv2(center);
  // }
}
.m-l-hide-sidebar-in-sm {
  .m-c-sidebar {
    @media screen and (max-width: 891px) {
      @include transform(translateX(-63px));
    }
  }
}
.m-l-show-sidebar-in-sm {
  .m-c-sidebar {
    @media screen and (max-width: 891px) {
      @include transform(translateX(0px));
      @include transition(all ease 0.4s);
    }
  }
}

.m-l-settings-menu-wrapper {
  background: $white;
  border: 1px solid #eae0e1;
  box-shadow: 0px 1px 4px rgba(17, 25, 45, 0.1);
  @include border-radius-all(6px);
  padding: 13px 0px;
  position: absolute;
  min-width: 188px;
  bottom: -80px;
  left: 55px;
  .m-l-settings-menu {
    padding-left: 0px;
    margin-bottom: 0px;
    list-style: none;
    .m-l-settings-menu-item {
      padding: 4px 15px;
      font-weight: $font-weight-semibold;
      font-size: $text-lg;
      line-height: 21px;
      cursor: pointer;
      color: $extra-dark;
      @include transition(color ease 0.2s, background-color ease 0.2s);
      &.active {
        background-color: $grid-row-selection;
        color: $app-primary-blue;
      }
      &:hover {
        background-color: $grid-row-selection;
        color: $app-primary-blue;
      }
    }
  }
}
.m-l-sidebar-tooltip {
  min-height: 14px;
}
.m-l-nav-drp-wrapper {
  position: relative;
}
.m-l-sidebar-icon {
  width: 20px;
  height: 20px;
}
.m-l-sidebar-item-wrapper {
  .m-l-settings-dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
.m-l-settings-dropdown-options-wrap {
  &.dx-popup-wrapper {
    .dx-popup-normal {
      border: 1px solid $bd-color3;
      box-shadow: 0px 1px 4px rgba(17, 25, 45, 0.1);
      @include border-radius-all(6px!important);
      transform: translate(49px, -50px) !important;
      overflow: auto;
      .dx-dropdownbutton-content {
        .dx-list {
          padding: 13px 0px;
          .dx-list-item {
            .dx-list-item-content {
              padding: 4px 15px !important;
              font-weight: $font-weight-semibold;
              font-size: $text-lg;
              line-height: 21px;
              color: $extra-dark;
            }
            &.dx-state-hover,
            &.dx-state-active,
            &.dx-state-focused {
              .dx-list-item-content {
                color: $app-primary-blue;
              }
            }
          }
        }
      }
    }
  }
}
