.m-c-header-section {
  position: absolute;
  width: 100%;
  height: 54px;
  background: $body-color;
  z-index: 4;
  // padding: 0px 20px 0px 54px;
  border-bottom: 1px solid $app-tertiary-border-color;
  box-shadow: none;
  @include flexboxv2();
  @include align-itemsv2(center);
  @media screen and (max-width: 891px) {
    height: auto;
    // display: block;
    @include flexboxv2();
    @include flex-directionv2(column);
  }
  // @media screen and (max-width: 600px) {
  //   display: block;
  // }
  // @media screen and (max-width: 480px) {
  //   @include flexboxv2();
  //   @include flex-directionv2(column);
  // }
  position: fixed;
  width: calc(100% - 62px);
  @media screen and (max-width: 891px) {
    position: absolute;
    width: 100%;
  }
  .m-l-header-left {
    width: 100%;
    height: 100%;
    padding: 10px;
    @include flexboxv2();
    @include align-itemsv2(center);

    @media screen and (max-width: 891px) {
      padding-bottom: 8px;
      padding-top: 16px;
      @include align-itemsv2(flex-start);
      @include flex-directionv2(column);
      order: 2;
    }

    // @media screen and (max-width: 676px) {
    //   display: block;
    // }

    // @media screen and (max-width: 600px) {
    //   padding-bottom: 12px;
    //   padding-top: 12px;
    // }

    .m-l-header-search-area-block {
      position: relative;
      width: 100%;
      max-width: 415px;
      min-width: 250px;
      @media screen and (max-width: 891px) {
        max-width: 100% !important;
        width: 100%;
      }
      .m-c-input-control {
        // width: 415px;
        width: 100%;
        @media screen and (max-width: 891px) {
          width: 100%;
        }
        // @media screen and (max-width: 768px) {
        //   width: 100%;
        // }
      }
      .m-c-search-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .m-l-header-advance-search-text {
      font-size: $text-md;
      font-weight: $font-weight-bold;
      color: $app-brand-primary;
      text-transform: capitalize;
      padding-left: 8px;
      text-decoration: underline;
    }
    .m-l-header-advance-search-block {
      margin-left: 12px;
      background-color: transparent;
      border-color: transparent;
      &.dx-state-hover {
        background-color: #e3eaf5;
        border-color: transparent;
      }
      // @media screen and (max-width: 676px) {
      //   margin-left: 0px;
      // }
      @media screen and (max-width: 891px) {
        margin-top: 10px;
        margin-left: 0px;
      }
      .dx-button-content {
        @include flexboxv2();
        @include align-itemsv2(center);
        .dx-field-label {
          width: 100%;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }

  .m-l-header-right {
    margin-left: auto;
    position: relative;
    padding-right: 10px;
    @include flexboxv2();
    @include align-itemsv2(center);

    @media screen and (max-width: 891px) {
      // padding-bottom: 12px;
      // @include justify-contentv2(center);
      width: 100%;
      margin-top: 0px;
      margin-left: 0px;
      padding: 16px 16px 0px;
      @include flex-wrapv2(wrap);
      order: 1;
    }
    // @media screen and (max-width: 600px) {
    //   margin-left: 16px;
    // }
    // @media screen and (max-width: 480px) {
    //   margin-top: 20px;
    //   margin-left: 0px;
    //   padding: 14px 16px 0px;
    //   @include flex-wrapv2(wrap);
    //   order: 1;
    // }

    .m-l-profile-block {
      display: block;
      position: relative;
      height: 32px;
      width: 32px;
      min-width: 32px;
      min-height: 32px;
      overflow: hidden;
      // margin: 0 auto;
      // margin: 0 12px 0px 0px;
      cursor: pointer;

      @include border-radius-all(50%);
      @include flexboxv2();
      @include align-itemsv2(center);

      @media screen and (max-width: 891px) {
        margin: 0;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .m-l-avatar-block {
      display: block;
      margin: 0px;
      position: relative;
      height: 32px;
      width: 32px;
      min-width: 32px;
      min-height: 32px;
      overflow: hidden;
      // margin: 0 auto;
      // margin: 0 12px 0px 0px;
      cursor: pointer;
      border: 2px solid $plc-color;

      @include border-radius-all(50%);
      @include flexboxv2();
      @include align-itemsv2(center);

      @media screen and (max-width: 891px) {
        margin: 0;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .m-l-notification-block {
      position: relative;
      padding-left: 20px;
      margin-right: 22px;
      @include flexboxv2();
      @include align-itemsv2(center);
      cursor: pointer;

      @media screen and (max-width: 420px) {
        padding-left: 12px;
        margin-right: 16px;
      }
    }
    .m-l-notification-mark {
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background-color: $red;
      position: absolute;
      top: 1px;
      right: 3px;
    }
    .m-l-user-name-block {
      @include flexboxv2();
      @include align-itemsv2(center);
    }
    .m-l-header-welcome-text {
      font-size: $text-lg;
      font-weight: $font-weight-semibold;
      color: $app-brand-primary;
      padding-right: 5px;
    }
    .m-l-header-user-name {
      font-size: $text-lg;
      font-weight: $font-weight-semibold;
      color: $app-brand-primary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 115px;
      &.dx-field-label {
        padding: 0;
      }

      @media screen and (max-width: 600px) {
        max-width: 100px;
        width: 100%;
      }
      @media screen and (max-width: 480px) {
        max-width: 80px;
      }
      @media screen and (max-width: 420px) {
        max-width: 45px;
      }
    }
    .m-l-notification-list-block {
      background-color: $white;
      width: 440px;
      border: 1px solid $notification-border;
      position: absolute;
      top: 35px;
      right: 72px;
      overflow: auto;
      @include border-radius-all(4px);
      @include boxShadow(0px 0px 8px rgba(0, 0, 0, 0.15));
      @include transition(all 0.3s ease);

      @media screen and (max-width: 891px) {
        top: 52px;
        right: 72px;
        // width: 100%;
        // margin: 0px 5px;
      }

      @media screen and (max-width: 600px) {
        top: 52px;
        right: 0px;
        width: 100%;
        // margin: 0px 5px;
      }
      .m-l-notification-list-block-header {
        padding: 16px;
        // border-bottom: 1px solid $notification-border;
        @include flexboxv2();
        @include align-itemsv2(center);

        @media screen and (max-width: 420px) {
          display: block;
        }
        .left-block {
          flex: 1;
          width: 40%;
          @media screen and (max-width: 420px) {
            width: 100%;
          }
          h5 {
            font-size: $text-lg;
            font-weight: $font-weight-bold;
            color: $app-brand-primary;
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .right-block {
          margin-left: auto;
          @include flexboxv2();
          @include align-itemsv2(center);

          @media screen and (max-width: 420px) {
            margin-left: 0;
            margin-top: 16px;
          }
          span {
            font-size: $text-md;
            font-weight: $font-weight-normal;
            color: $app-brand-secondary;
            margin-right: 17px;
          }
        }
      }
      .m-l-notification-list-block-body {
        padding: 0px 0px 0px;
        position: relative;

        @media screen and (max-width: 480px) {
          @include flexboxv2();
          @include flex-directionv2(column);
        }
        .m-l-time-line-block {
          padding: 0px 16px;
          margin-top: 10px;
          @include flexboxv2();
          @include align-itemsv2(center);
          @include justify-contentv2(space-between);
        }
        .today-text {
          font-size: $text-md;
          color: $plc-color;
          font-weight: $font-weight-bold;
          margin-bottom: 0px;
        }
        .mark-read--text {
          font-size: $text-md;
          color: $dark-blue;
          font-weight: $font-weight-normal;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: $app-primary-blue;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          // max-height: 482px;
          // overflow: auto;
          li {
            padding: 16px;
            cursor: pointer;
            @include flexboxv2();
            @include align-itemsv2(center);
            // &:first-child {
            //   border-bottom: 1px solid $notification-border;
            // }
            .left-block {
              @include flexboxv2();
              @include align-itemsv2(center);
            }
            .right-block {
              margin-left: auto;
            }
            .m-l-user-image-block {
              margin-right: 19px;
            }
            .m-l-user-details-section {
              .m-l-user-details-block {
                @include flexboxv2();
                @include align-itemsv2(center);
                @include flex-wrapv2(wrap);
                .m-l-company-name {
                  font-size: $text-md;
                  color: $text-color;
                  font-weight: $font-weight-bold;
                  margin-bottom: 0px;
                  margin-right: 3px;
                }
                span {
                  font-size: $text-md;
                  color: $app-brand-secondary;
                  font-weight: $font-weight-normal;
                  margin-right: 3px;
                }
                .m-l-status--pending {
                  font-size: $text-md;
                  color: $secondary-brown;
                  font-weight: $font-weight-bold;
                  margin-bottom: 0px;
                }
                .m-l-status--approved {
                  font-size: $text-md;
                  color: $sucess;
                  font-weight: $font-weight-bold;
                  margin-bottom: 0px;
                }
                .m-l-status--rejected {
                  font-size: $text-md;
                  color: $red;
                  font-weight: $font-weight-bold;
                  margin-bottom: 0px;
                }
              }
              p {
                font-size: $text-md;
                color: $plc-color;
                font-weight: $font-weight-bold;
                margin-top: 4px;
                margin-bottom: 0px;
              }
            }
            + li {
              border-top: 1px solid $notification-border;
            }
          }
        }
        .m-l-toggle-switch-block {
          @include flexboxv2();
          @include align-itemsv2(center);
          position: absolute;
          top: 13px;
          right: 16px;
          @media screen and (max-width: 480px) {
            order: 1;
            padding: 0px 16px;
            position: initial;
            @include justify-contentv2(flex-end);
          }
          span {
            font-size: $text-md;
            color: $app-brand-secondary;
            font-weight: $font-weight-normal;
          }
          .m-l-toggle-switch {
            margin-left: 16px;
          }
        }
        .dx-tabpanel {
          @media screen and (max-width: 480px) {
            order: 2;
          }
        }
      }
    }
    .m-l-header-item-btn-wrapper {
      margin-right: 16px;
      .m-l-bgsm-btn {
        font-weight: $font-weight-semibold;
        font-size: $text-lg;
        line-height: 1.71;
        color: $app-brand-primary;
        min-height: 27px;
        min-width: 61px;
        padding: 4px;
        @include flexboxv2();
        @include align-itemsv2(center);
        @include justify-contentv2(center);
        background-color: $bt-bg-color;
        border: 1px solid rgba($dark-blue, 0.6);
        @include border-radius-all(4px);
        @include transition(box-shadow ease 0.2s);
        white-space: nowrap;
        @media screen and (max-width: 420px) {
          min-width: 51px;
        }
        &.dx-state-hover,
        &.dx-state-focus {
          @include boxShadow(0px 1px 5px $gray-lighter);
        }
        .dx-button-content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 120px;
          padding: 0;
          line-height: normal;

          @media screen and (max-width: 420px) {
            max-width: 100px;
          }
          @media screen and (max-width: 414px) {
            max-width: 95px;
          }
          @media screen and (max-width: 360px) {
            max-width: 50px;
          }
        }
      }
    }
    .m-l-header-right-menu-block {
      display: none;
      &.m-l-tgl-icon-wrap {
        .sidebar-arrow-btn-sm {
          background-color: transparent;
          border: none;
          .dx-button-content {
            padding: 0px;
          }
        }
      }
      @media screen and (max-width: 891px) {
        display: inline-block;
      }
    }
    .m-l-header-right-admin-block {
      @include flexboxv2();
      @include align-itemsv2(center);

      @media screen and (max-width: 891px) {
        margin-left: auto;
      }
    }
  }
}
.m-l-input-search-area-block {
  width: 100%;
  position: relative;
  .m-c-input-control {
    width: 221px;
    @media screen and (max-width: 420px) {
      width: 100%;
    }
  }
  .m-c-search-icon {
    position: absolute;
    top: 7px;
    right: 8px;
  }
}
.m-l-user-drp-dwn {
  position: absolute;
  opacity: 0;
}
.m-l-show-sidebar-in-sm {
  .m-c-header-section {
    transition: padding-left 0.4s ease;
    @media screen and (max-width: 891px) {
      padding-left: 60px;
    }
  }
  .m-l-container {
    transition: padding-left 0.4s ease;
    @media screen and (max-width: 891px) {
      padding-left: 60px;
    }
  }
}
.m-l-hide-sidebar-in-sm {
  .m-c-header-section {
    transition: padding-left 0.4s ease;
    @media screen and (max-width: 891px) {
      padding-left: 0px;
    }
  }
  .m-l-container {
    transition: padding-left 0.4s ease;
    @media screen and (max-width: 891px) {
      padding-left: 0px;
    }
  }
}
.notification-wrapper {
  position: relative;
  max-height: 410px;
  overflow: auto;
  .m-l-mark-read {
    position: absolute;
    right: 16px;
    top: 8px;
  }
}
