//login page styles

.m-l-login-container {
  @include flexboxv2();
  @media screen and (max-width: 991px) {
    flex-direction: column;
    height: 100%;
  }
  .m-l-login-left-part {
    flex-basis: 50%;
    .m-l-login-bg {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      @media screen and (max-width: 991px) {
        height: 300px;
        width: 100%;
      }
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  .m-l-login-right-part {
    // padding: 15px 107px;
    @include flexboxv2();
    @include align-itemsv2(center);
    @include justify-contentv2(center);
    width: 100%;
    flex-basis: 360px;
    @media screen and (max-width: 991px) {
      // padding: 30px 15px;
      height: 100%;
      flex-basis: 100%;
    }
  }
  .m-l-login-data-cntr {
    width: 100%;
    max-width: 385px;
    .m-l-login-data-title {
      font-weight: $font-weight-bold;
      font-size: $text-2xl-plus;
      line-height: 1.5;
      color: $app-brand-primary;
      margin-bottom: 8px;
      &.m-l-sm-title {
        font-size: $text-xl-plus;
        text-align: center;
        margin-bottom: 16px;
      }
    }
    .m-l-login-data-sub {
      font-weight: $font-weight-normal;
      font-size: $text-lg;
      line-height: 1.71;
      color: $plc-color;
      margin-bottom: 22px;
      @media screen and (max-width: 991px) {
        margin-bottom: 16px;
      }
      &.m-l-align-data {
        text-align: center;
      }
    }
  }
  .m-l-login-form {
    &.m-l-sucess-block {
      min-height: 283px;
      @media screen and (max-width: 991px) {
        min-height: auto;
      }
    }
    .m-c-form-group {
      .m-c-input-control {
        .dx-texteditor-container {
          .dx-texteditor-input-container {
            .dx-texteditor-input {
              height: 35px;
            }
          }
        }
      }
      + .m-c-form-group {
        margin-top: 26px;
        @media screen and (max-width: 991px) {
          margin-top: 24px;
        }
      }
    }
    .m-l-fg-pwd-wrap {
      @include flexboxv2();
      @include justify-contentv2(space-between);
      margin-top: 8px;
      margin-bottom: 26px;
      .m-fg-pwd-right {
        .m-l-fg-frgt-link {
          font-weight: $font-weight-semibold;
          font-size: $text-lg;
          line-height: 1.71;
          color: $app-brand-primary;
          cursor: pointer;
        }
      }
    }
    .m-l-login-btn-wrap {
      .app-c-btn {
        height: 35px;
        width: 100%;
        @include border-radius-all(5px);
        font-size: $text-lg;
        font-weight: $font-weight-semibold;
      }
    }
  }

  .m-l-login-footer-wrapper {
    margin-top: 48px;
    text-align: center;
    .m-l-pwrd-by {
      font-weight: $font-weight-normal;
      font-size: $text-md;
      line-height: 1.33;
      color: $app-secondary-border-color;
      margin-bottom: 11px;
    }
    .m-l-lg-footer-logo {
      .m-l-lgn-img {
        margin-bottom: 22px;
      }
    }
    .m-l-contact-info {
      font-weight: $font-weight-semibold;
      font-size: $text-lg;
      line-height: 1.71;
      color: $app-secondary-border-color;
    }
  }
}
.m-l-back-to-link-wrap {
  margin-top: 24px;
  text-align: center;
  text-decoration: underline;
  .m-l-back-tolink {
    font-weight: $font-weight-semibold;
    font-size: $text-lg;
    line-height: 1.71;
    color: $app-brand-primary;
    cursor: pointer;
    @include transition(color ease 0.2s);
    &:hover {
      color: $app-primary-blue;
    }
  }
}
.m-l-aprve-img-wrap {
  @include flexboxv2();
  @include justify-contentv2(center);
  margin-bottom: 16px;
}
.m-l-lgn-success {
  font-weight: $font-weight-normal;
  font-size: $text-lg;
  line-height: 1.71;
  color: $plc-color;
  margin-bottom: 24px;
  text-align: center;
}
.m-l-login-wrapper {
  height: 100%;
}
